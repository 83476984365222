import { Component, OnInit, TemplateRef } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import * as global from '../services/global-functions'
import { StateService } from '@uirouter/angular';
import { environment } from '@environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private modalService: BsModalService, private state: StateService, private config: ConfigurationService, private http: HttpClient) { 
        this.minisitesList = []
    }

    minisitesList: any;
    returnedArray: any;
    typeList = {};
    type;

    modalRef: BsModalRef;
    message: string;

    searchInTable() {
        var searchTerm = $("#mySearch").val();
        var searchSplit = searchTerm.replace(/ /g, "'):containsi('");

        $.extend($.expr[':'], {'containsi': (elem, i, match, array) => {
            return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
        });

        $(".results tbody tr").not(":containsi('" + searchSplit + "')").each((e) => {
            for(var i = 0; i < $(".results tbody tr").length; i++) {
                $(".results tbody tr")[i].setAttribute('visible','false');
            }
        });

        $(".results tbody tr:containsi('" + searchSplit + "')").each((e) => {
            for(var i = 0; i < $(".results tbody tr:containsi('" + searchSplit + "')").length; i++) {
                $(".results tbody tr:containsi('" + searchSplit + "')")[i].setAttribute('visible','true');
            }
        });
    }

    pageChanged(event: PageChangedEvent): void {
        const startItem = (event.page - 1) * event.itemsPerPage;
        const endItem = event.page * event.itemsPerPage;
        this.returnedArray = this.minisitesList.slice(startItem, endItem);
    }

    dynamicSort(property) {
        var sortOrder = 1;
    
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
    
        return function (a,b) {
            if(sortOrder == -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }

    sortOrder: string = "desc";

    orderListBy(property) {
        if(this.sortOrder === "asc") {
            this.minisitesList.sort(this.dynamicSort(property));
            this.sortOrder = "desc";
        } else if(this.sortOrder === "desc") {
            this.minisitesList.sort(this.dynamicSort("-"+property));
            this.sortOrder = "asc";
        }

        this.returnedArray = this.minisitesList.slice(0,1000);
    }

    openModal(template: TemplateRef<any>, minisite) {
        var initialState = {name: minisite['name'], venue: minisite['venue'], url: minisite['url']};
        this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});
        this.modalRef.content = initialState;
    }
    
    confirm(minisite): void {
        this.deleteMinisite(minisite);
        this.modalRef.hide();
    }
    
    decline(): void {
        this.modalRef.hide();
    }

    deleteMinisite(minisite) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.delete(environment.deleteUrl+minisite['url'], httpOptions).subscribe(
            val => {
                this.listMinisites();
            },
            err => {
                console.error(err);
            }
        )
    }

    editMinisite(minisite) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.get(environment.getClientUrl+minisite['url'], httpOptions).subscribe(
        val => {
            for (let [key, value] of Object.entries(val)){
                if(val['flag'] === "TK3D") {
                    this.config.configurationFile[key] = value;
                    this.state.go('common.content');
                } else if(val['flag'] === "DVM") {
                    this.config.configurationFileDVM[key] = value;
                    this.state.go('common.contentdvm');
                }
            }

            if(val['flag'] === "TK3D") {
                this.config.breadcrumbs.push({name:"Content", state:"common.content"});
            } else if(val['flag'] === "DVM") {
                this.config.breadcrumbs.push({name:"Content", state:"common.contentdvm"});
            }
        },
        error => { console.error(error) }
        );
    }

    listMinisites() {
        this.minisitesList = [];
        const httpOptions = {
            headers: new HttpHeaders({
            'Authorization': "Bearer " + global.getCookie('access_token')
            })
        };

        this.http.get(environment.listUrl, httpOptions).subscribe(
            val => {
                for(var i in val) {
                    this.minisitesList.push(val[i]);
                }
                this.returnedArray = this.minisitesList.slice(0,1000);
                this.getMinisiteInfo(this.minisitesList)

            },
            error => { console.log(error) }
        );
        
    }

    getMinisiteInfo(list) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': "Bearer " + global.getCookie('access_token')
            })
        }

        this.minisitesList = [];
        for(var j = 0; j < list.length; j++) {
            this.http.get(environment.getClientUrl+list[j], httpOptions).subscribe(
                val => {
                    
                    if(val['integrationType'] === 'dvm') {
                        if(val['libraryVersion']){
                            val['flag'] = "DVM - " + val['libraryVersion'];
                        } else {
                            val['flag'] = "DVM - stable"; 
                        }
                    } else if(val['integrationType'] === 'bumper') {
                        if(val['libraryVersion']){
                            val['flag'] = "BUMPER - " + val['libraryVersion'];
                        } else {
                            val['flag'] = "BUMPER - stable"; 
                        }
                    } else {
                        val['flag'] = val["flag"]
                    }

                    this.minisitesList.push({name:val['venueName'], url:val['minisiteUrl'],flag:val['flag'], venue:val['venueID'], viewer:val['viewerIntegration']});
                    this.minisitesList.sort(this.dynamicSort("name"));
                    this.returnedArray = this.minisitesList.slice(0,1000);
                }, 
                err => {
                    
                }
            )
        }
    }

    ngOnInit() {
        if(!global.getCookie('access_token')) {
            this.state.go('common.login');
        } else {
            this.listMinisites();

            this.config.logo = null;
            this.config.logoPreview = null;
            this.config.background = null;
            this.config.bgPreview = null;
            this.config.favicon = null;
            this.config.favPreview = null;

            this.config.configurationFile = {
                flag: "TK3D",
                minisiteUrl: "",
                mapID: "",
                minimapID: "",
                venueID: "",
                venueName: "",
                subtitleName: "",
                viewType: "",
                viewerIntegration:"old",
                exceptions: [],
                ticketsUrl: "",
                logoUrl: "",
                logoType: "",
                faviconType:"",
                hasPano:true,
                hasMinimap: true,
                buyTickets: true,
                filters: false,
                searchbar: true,
                tabs: false,
                tabsManual:false,
                neighbours: false,
                leftRight: false,
                unavailable: false,
                navigation: false,
                translation: false,
                infoMenuCheck: false,
                infoMenuOptions:[],
                infoMenuTitle: "",
                logo:true,
                bg:true,
                favicon:true,
                backgroundColor: "",
                popupJsonCheck:false, 
                popupJson: [],
                popupImg: "",
                bgHeader: "",
                txtHeader: "",
                bgTopbar:"",
                ticketLab: "",
                ticketVal: "",
                bgPopover: "",
                popoverLab: "",
                popoverVal: "",
                bgButton: "",
                buttonLab: "",
                blockAvailable:"",
                blockAvailableOpacity: "",
                blockAvailableHover:"",
                blockAvailableHoverOpacity: "",
                blockUnavailable:"",
                blockUnavailableOpacity: "",
                blockUnavailableStroke:"",
                blockUnavailableStrokeOpacity:"",
                blockSelected:"",
                blockSelectedOpacity: "",
                blockSelectedHover:"",
                blockSelectedHoverOpacity: "",
                blockFiltered:"",
                blockFilteredOpacity:"",
                blockFilteredHover:"",
                blockFilteredHoverOpacity:"",
                blockFilteredUnavailable: "",
                blockFilteredUnavailableOpacity: "",
                blockAvailableStroke:"",
                blockAvailableStrokeOpacity:"",
                blockAvailableHoverStroke:"",
                blockAvailableHoverStrokeOpacity:"",
                blockSelectedStroke:"",
                blockSelectedStrokeOpacity:"",
                blockSelectedHoverStroke:"",
                blockSelectedHoverStrokeOpacity:"",
                blockFilteredStroke:"",
                blockFilteredStrokeOpacity:"",
                blockFilteredHoverStroke:"",
                blockFilteredHoverStrokeOpacity:"",
                blockFilteredUnavailableStroke:"",
                blockFilteredUnavailableStrokeOpacity:"",
                seatAvailable:"",
                seatAvailableOpacity:"",
                seatAvailableHover:"",
                seatAvailableHoverOpacity:"",
                seatUnavailable:"",
                seatUnavailableOpacity:"",
                seatSelected:"",
                seatSelectedOpacity:"",
                seatSelectedHover:"",
                seatSelectedHoverOpacity:"",
                exceptionsJsonCheck: false,
                blockOpacity:"",
                seatOpacity:"",
                copyLinkCheck: true
            }

            this.config.configurationFileDVM = {
                flag: "DVM",
                integrationType:"",
                libraryVersion:"",
                viewerIntegration:"",
                minisiteUrl: "",
                mapID: "",
                venueID: "",
                minimapID: "",
                venueName: "",
                subtitleName: "",
                viewType: "",
                hasMinimap: true,
                hasPano: true,
                unavailable: false,
                exceptions: [],
                ticketsUrl: "",
                logoUrl: "",
                logoType: "",
                faviconType:"",
                ticketsName: "",
                filters: false,
                navigation: false,
                buyTickets: true,
                tabs: false,
                translation: false,
                tabsManual:false,
                neighbours:false,
                leftRight: false,
                searchbar: true,
                bestNodesCheck: false,
                infoMenuCheck: false,
                infoMenuOptions: [],
                infoMenuTitle: "",
                logo:true,
                bg:true,
                favicon:true,
                backgroundColor: "",
                navigationPluginCheck:false,
                galleryPluginCheck:false,
                indoorPluginCheck:false,
                popupJsonCheck:false, 
                multiIndoorCheck: false,
                popupJson: [],
                popupImg: "",
                bgHeader: "",
                txtHeader: "",
                bgTopbar:"",
                ticketLab: "",
                ticketVal: "",
                bgPopover: "",
                popoverLab: "",
                popoverVal: "",
                bgButton: "",
                buttonLab: "",
                blockAvailable:"",
                blockAvailableOpacity:"",
                blockAvailableStroke:"",
                blockAvailableStrokeOpacity:"",
                blockAvailableLineWidth:"",
                blockAvailableHover:"",
                blockAvailableHoverOpacity:"",
                blockAvailableHoverStroke:"",
                blockAvailableHoverStrokeOpacity:"",
                blockAvailableHoverLineWidth:"",
                blockSelected:"",
                blockSelectedOpacity:"",
                blockSelectedStroke:"",
                blockSelectedStrokeOpacity:"",
                blockSelectedLineWidth:"",
                blockSelectedHover:"",
                blockSelectedHoverOpacity:"",
                blockSelectedHoverStroke:"",
                blockSelectedHoverStrokeOpacity:"",
                blockSelectedHoverLineWidth:"",
                blockUnavailable:"",
                blockUnavailableOpacity:"",
                blockUnavailableStroke:"",
                blockUnavailableStrokeOpacity:"",
                blockFiltered:"",
                blockFilteredOpacity:"",
                blockFilteredHover:"",
                blockFilteredHoverOpacity:"",
                blockFilteredUnavailable: "",
                blockFilteredUnavailableOpacity: "",
                blockFilteredStroke:"",
                blockFilteredStrokeOpacity:"",
                blockFilteredHoverStroke:"",
                blockFilteredHoverStrokeOpacity:"",
                blockFilteredUnavailableStroke:"",
                blockFilteredUnavailableStrokeOpacity:"",
                blockFilteredHoverLineWidth:"",
                blockFilteredLineWidth:"",
                seatAvailable:"",
                seatAvailableOpacity:"",
                seatAvailableStroke:"",
                seatAvailableStrokeOpacity:"",
                seatAvailableLineWidth:"",
                seatAvailableHover:"",
                seatAvailableHoverOpacity:"",
                seatAvailableHoverStroke:"",
                seatAvailableHoverStrokeOpacity:"",
                seatAvailableHoverLineWidth:"",
                seatSelected:"",
                seatSelectedOpacity:"",
                seatSelectedStroke:"",
                seatSelectedStrokeOpacity:"",
                seatSelectedLineWidth:"",
                seatSelectedHover:"",
                seatSelectedHoverOpacity:"",
                seatSelectedHoverStroke:"",
                seatSelectedHoverStrokeOpacity:"",
                seatSelectedHoverLineWidth:"",
                seatUnavailable:"",
                seatUnavailableOpacity:"",
                exceptionsJsonCheck: false,
                copyLinkCheck: true
            }
        }
    }
}

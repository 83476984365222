import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { PreviewDVMService } from '../preview-dvm.service';
declare var $:any;

@Component({
selector: 'app-header-dvm',
templateUrl: './header-dvm.component.html',
styleUrls: ['./header-dvm.component.scss']
})
export class HeaderDvmComponent implements OnInit {
    venueName = this.config.configurationFileDVM.venueName;
    imgUrl = this.config.configurationFileDVM.minisiteUrl;
    imgPreview = this.config.logoPreview;
    logoUrl:any;
    url = this.config.configurationFileDVM.ticketsUrl;
    buyTickets = this.config.configurationFileDVM.buyTickets;
    searchbar = this.config.configurationFileDVM.searchbar;
    txtHeader = this.config.configurationFileDVM.txtHeader;
    bgButton = this.config.configurationFileDVM.bgButton;
    buttonLab = this.config.configurationFileDVM.buttonLab;
    buttonText = this.config.configurationFileDVM.ticketsName;

    count = 0;

    get results() {
        var selection = [];
        var searchVal = $("#search").val().toString().toLowerCase();
        var allBlocks = this.dvm.getAllBlocks();

        if (searchVal !== "") {
            var result = [];

            for(var i = 0; i < allBlocks.length; i++) {
                if (allBlocks[i].id.toLowerCase().indexOf(searchVal) !== -1) {
                    selection.push(allBlocks[i].id);
                }
            }

            if(this.count === 0){
                this.filterSectionOnBlockmap(selection);
            }

        } else {
            this.filterSectionOnBlockmap(allBlocks);
        }
        
        return selection;
    }

    filterSectionOnBlockmap(section) {
		this.dvm.filterSectionOnBlockmap(section);
    }
    
    locateSeatmap(id){
		this.resetSearch();
		$(".contact-btn").addClass("hidden");
		$(".interior").addClass("hidden");
		$(".interior-mobile").addClass("hidden");

		this.dvm.locateSeatmap(id);
	}

	onClick(element) {
		this.locateSeatmap(element);
	}

	mouseEnter(element) {
        this.count = 1;
		this.filterSectionOnBlockmap(element);
	}

	mouseOut() {
		this.count = 0;
	}

	resetSearch(){
		$('.form-group input').blur();
		$('.form-group input').val('');
    }
    
    get image() {
        this.logoUrl = this.imgPreview ? this.imgPreview : `https://3ddigitalvenue.com/3dmap/pro-sites/${this.imgUrl}/logo.png`;
        return this.logoUrl;
    }

    constructor(private config: ConfigurationService, private dvm: PreviewDVMService) { }

    ngOnInit() {
    }

}

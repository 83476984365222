import { Component, OnInit } from '@angular/core';
import { PreviewDVMService } from './preview-dvm.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StateService } from '@uirouter/angular';
import { environment } from 'src/environments/environment';
import * as global from '../../services/global-functions'
declare var $: any;


@Component({
  selector: 'app-preview-dvm',
  templateUrl: './preview-dvm.component.html',
  styleUrls: ['./preview-dvm.component.scss']
})
export class PreviewDVMComponent implements OnInit {
  constructor(private config: ConfigurationService, private dvm: PreviewDVMService,
    private http: HttpClient, private state: StateService) { }

  uploadMinisite() {
    if(this.config.configurationFileDVM.minisiteUrl) {
      var st = JSON.stringify(this.config.configurationFileDVM);
      var blob = new Blob([st], {type:'application/json'});
      var file = new File([blob], 'config.json', {type:'application/json'});
  
      if(this.config.logo) {
        if(this.config.logo.name.split(".")[1] === "svg" && this.config.logo.type === "image/svg+xml") {
          var blob2 = new Blob([this.config.logo], {type:'image/svg+xml'});
          var file2 = new File([blob2], "logo.svg",{type:'image/svg+xml'});
        } else if(this.config.logo.name.split(".")[1] === "png" && this.config.logo.type === "image/png") {
          var blob2 = new Blob([this.config.logo], {type:'image/png'});
          var file2 = new File([blob2], "logo.png",{type:'image/png'});
        }
    }
  
      if(this.config.background) {
          var blob3 = new Blob([this.config.background], {type:'image/jpg'});
          var file3 = new File([blob3], "bg.jpg", {type:'image/jpg'});
      }
  
      if(this.config.favicon) {
        if(this.config.favicon.name.split(".")[1] === "svg" && this.config.favicon.type === "image/svg+xml") {
          var blob4 = new Blob([this.config.favicon], {type:'image/svg+xml'});
          var file4 = new File([blob4], "favicon.svg",{type:'image/svg+xml'});
        } else if(this.config.favicon.name.split(".")[1] === "png" && this.config.favicon.type === "image/png") {
          var blob4 = new Blob([this.config.favicon], {type:'image/png'});
          var file4 = new File([blob4], "favicon.png",{type:'image/png'});
        } else if(this.config.favicon.name.split(".")[1] === "ico" && this.config.favicon.type === "image/x-icon") {
          var blob4 = new Blob([this.config.favicon], {type:'image/x-icon'});
          var file4 = new File([blob4], "favicon.ico",{type:'image/x-icon'});
        } else if(this.config.favicon.name.split(".")[1] === "ico" && this.config.favicon.type === "image/vnd.microsoft.icon") {
          var blob4 = new Blob([this.config.favicon], {type:'image/vnd.microsoft.icon'});
          var file4 = new File([blob4], "favicon.ico",{type:'image/vnd.microsoft.icon'});
        }
      } else if(!this.config.favicon && this.config.logo){
        if(this.config.logo.name.split(".")[1] === "svg" && this.config.logo.type === "image/svg+xml") {
          var blob4 = new Blob([this.config.logo], {type:'image/svg+xml'});
          var file4 = new File([blob4], "logo.svg",{type:'image/svg+xml'});
        } else if(this.config.logo.name.split(".")[1] === "png" && this.config.logo.type === "image/png") {
          var blob4 = new Blob([this.config.logo], {type:'image/png'});
          var file4 = new File([blob4], "logo.png",{type:'image/png'});
        }
      }
         
      let formData: FormData = new FormData();
      formData.append('json', file);
      formData.append('client', this.config.configurationFileDVM.minisiteUrl);
  
      if(file2) {
          formData.append('logo', file2);
      }
  
      if(file3) {
          formData.append('bg', file3);
      }
  
      if(file4){
          formData.append('favicon', file4);
      }
  
      console.log(formData)
  
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': "Bearer " + global.getCookie('access_token')
        })
      };
      
      return this.http.post(environment.uploadUrl, formData, {responseType:"text", headers:httpOptions.headers})
      .subscribe(
          val => { 
              this.config.breadcrumbs = [];
              this.state.go('common.home');
            },
          error => { console.log(error)}
      );
    }
  }

  ngOnInit() {
    //this.dvm.integrationType = this.config.configurationFileDVM.integrationType;
    this.dvm.loadMap();
    $("#header-wrap").css("background-color", this.config.configurationFileDVM.bgHeader);
    $(".toptitle").css("color", this.config.configurationFileDVM.txtHeader);
    $(".subtitle").css("color", this.config.configurationFileDVM.txtHeader);
    $(".app-topbar").css("background-color", this.config.configurationFileDVM.bgTopbar);
    $(".ticket-lab").css("color", this.config.configurationFileDVM.ticketLab);
    $(".ticket-val").css("color", this.config.configurationFileDVM.ticketVal);
    $(".link-wrap a").css("background-color", this.config.configurationFileDVM.bgButton);
    $(".link-wrap a").css("color", this.config.configurationFileDVM.buttonLab);
  }

}

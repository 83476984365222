import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
declare var $: any;


@Component({
  selector: 'app-custom-dvm',
  templateUrl: './custom-dvm.component.html',
  styleUrls: ['./custom-dvm.component.scss']
})
export class CustomDVMComponent implements OnInit {
  buyTickets: boolean = this.config.configurationFileDVM.buyTickets;
  
  bgHeader: string = this.config.configurationFileDVM.bgHeader;
  txtHeader: string = this.config.configurationFileDVM.txtHeader;
  bgTopbar: string = this.config.configurationFileDVM.bgTopbar;
  ticketLab: string = this.config.configurationFileDVM.ticketLab;
  ticketVal: string = this.config.configurationFileDVM.ticketVal;
  bgPopover: string = this.config.configurationFileDVM.bgPopover;
  popoverLab: string = this.config.configurationFileDVM.popoverLab;
  popoverVal: string = this.config.configurationFileDVM.popoverVal;
  bgButton: string = this.config.configurationFileDVM.bgButton;
  buttonLab: string = this.config.configurationFileDVM.buttonLab;

  blockAvailable:string = this.config.configurationFileDVM.blockAvailable ? this.config.configurationFileDVM.blockAvailable : "";
  blockAvailableOpacity:string = this.config.configurationFileDVM.blockAvailableOpacity ? this.config.configurationFileDVM.blockAvailableOpacity : "";
  blockAvailableStroke:string = this.config.configurationFileDVM.blockAvailableStroke ? this.config.configurationFileDVM.blockAvailableStroke : "";
  blockAvailableStrokeOpacity:string = this.config.configurationFileDVM.blockAvailableStrokeOpacity ? this.config.configurationFileDVM.blockAvailableStrokeOpacity : "";
  blockAvailableLineWidth:string = this.config.configurationFileDVM.blockAvailableLineWidth ? this.config.configurationFileDVM.blockAvailableLineWidth : "";

  blockAvailableHover:string = this.config.configurationFileDVM.blockAvailableHover ? this.config.configurationFileDVM.blockAvailableHover : "";
  blockAvailableHoverOpacity:string = this.config.configurationFileDVM.blockAvailableHoverOpacity ? this.config.configurationFileDVM.blockAvailableHoverOpacity : "";
  blockAvailableHoverStroke:string = this.config.configurationFileDVM.blockAvailableHoverStroke ? this.config.configurationFileDVM.blockAvailableHoverStroke : "";
  blockAvailableHoverStrokeOpacity:string = this.config.configurationFileDVM.blockAvailableHoverStrokeOpacity ? this.config.configurationFileDVM.blockAvailableHoverStrokeOpacity : "";
  blockAvailableHoverLineWidth:string = this.config.configurationFileDVM.blockAvailableHoverLineWidth ? this.config.configurationFileDVM.blockAvailableHoverLineWidth : "";
  
  blockSelected:string = this.config.configurationFileDVM.blockSelected ? this.config.configurationFileDVM.blockSelected : "";
  blockSelectedOpacity:string = this.config.configurationFileDVM.blockSelectedOpacity ? this.config.configurationFileDVM.blockSelectedOpacity : "";
  blockSelectedStroke:string = this.config.configurationFileDVM.blockSelectedStroke ? this.config.configurationFileDVM.blockSelectedStroke : "";
  blockSelectedStrokeOpacity:string = this.config.configurationFileDVM.blockSelectedStrokeOpacity ? this.config.configurationFileDVM.blockSelectedStrokeOpacity : "";
  blockSelectedLineWidth:string = this.config.configurationFileDVM.blockSelectedLineWidth ? this.config.configurationFileDVM.blockSelectedLineWidth : "";

  blockSelectedHover:string = this.config.configurationFileDVM.blockSelectedHover ? this.config.configurationFileDVM.blockSelectedHover : "";
  blockSelectedHoverOpacity:string = this.config.configurationFileDVM.blockSelectedHoverOpacity ? this.config.configurationFileDVM.blockSelectedHoverOpacity : "";
  blockSelectedHoverStroke:string = this.config.configurationFileDVM.blockSelectedHoverStroke ? this.config.configurationFileDVM.blockSelectedHoverStroke : "";
  blockSelectedHoverStrokeOpacity:string = this.config.configurationFileDVM.blockSelectedHoverStrokeOpacity ? this.config.configurationFileDVM.blockSelectedHoverStrokeOpacity : "";
  blockSelectedHoverLineWidth:string = this.config.configurationFileDVM.blockSelectedHoverLineWidth ? this.config.configurationFileDVM.blockSelectedHoverLineWidth : "";

  blockUnavailable:string = this.config.configurationFileDVM.blockUnavailable ? this.config.configurationFileDVM.blockUnavailable : "";
  blockUnavailableOpacity:string = this.config.configurationFileDVM.blockUnavailableOpacity ? this.config.configurationFileDVM.blockUnavailableOpacity : "";
  blockUnavailableStroke:string = this.config.configurationFileDVM.blockUnavailableStroke ? this.config.configurationFileDVM.blockUnavailableStroke : "";
  blockUnavailableStrokeOpacity:string = this.config.configurationFileDVM.blockUnavailableStrokeOpacity ? this.config.configurationFileDVM.blockUnavailableStrokeOpacity : "";

  blockFiltered:string = this.config.configurationFileDVM.blockFiltered ? this.config.configurationFileDVM.blockFiltered : "";
  blockFilteredOpacity: string = this.config.configurationFileDVM.blockFilteredOpacity ? this.config.configurationFileDVM.blockFilteredOpacity : "";
  blockFilteredStroke:string = this.config.configurationFileDVM.blockFilteredStroke ? this.config.configurationFileDVM.blockFilteredStroke : "";
  blockFilteredStrokeOpacity:string = this.config.configurationFileDVM.blockFilteredStrokeOpacity ? this.config.configurationFileDVM.blockFilteredStrokeOpacity : "";
  blockFilteredLineWidth:string = this.config.configurationFileDVM.blockFilteredLineWidth ? this.config.configurationFileDVM.blockFilteredLineWidth : "";

  blockFilteredHover: string =this.config.configurationFileDVM.blockFilteredHover ? this.config.configurationFileDVM.blockFilteredHover : "";
  blockFilteredHoverOpacity: string = this.config.configurationFileDVM.blockFilteredHoverOpacity ? this.config.configurationFileDVM.blockFilteredHoverOpacity : "";
  blockFilteredHoverStroke:string = this.config.configurationFileDVM.blockFilteredHoverStroke ? this.config.configurationFileDVM.blockFilteredHoverStroke : "";
  blockFilteredHoverStrokeOpacity:string = this.config.configurationFileDVM.blockFilteredHoverStrokeOpacity ? this.config.configurationFileDVM.blockFilteredHoverStrokeOpacity : "";
  blockFilteredHoverLineWidth: string = this.config.configurationFileDVM.blockFilteredHoverLineWidth ? this.config.configurationFileDVM.blockFilteredHoverLineWidth : "";

  blockFilteredUnavailable: string = this.config.configurationFileDVM.blockFilteredUnavailable ? this.config.configurationFileDVM.blockFilteredUnavailable : "";
  blockFilteredUnavailableOpacity: string = this.config.configurationFileDVM.blockFilteredUnavailableOpacity ? this.config.configurationFileDVM.blockFilteredUnavailableOpacity : "";
  blockFilteredUnavailableStroke:string = this.config.configurationFileDVM.blockFilteredUnavailableStroke ? this.config.configurationFileDVM.blockFilteredUnavailableStroke : "";
  blockFilteredUnavailableStrokeOpacity:string = this.config.configurationFileDVM.blockFilteredUnavailableStrokeOpacity ? this.config.configurationFileDVM.blockFilteredUnavailableStrokeOpacity : "";
  
  
  seatAvailable:string = this.config.configurationFileDVM.seatAvailable ? this.config.configurationFileDVM.seatAvailable : "";
  seatAvailableOpacity:string = this.config.configurationFileDVM.seatAvailableOpacity ? this.config.configurationFileDVM.seatAvailableOpacity : "";
  seatAvailableStroke:string = this.config.configurationFileDVM.seatAvailableStroke ? this.config.configurationFileDVM.seatAvailableStroke : "";
  seatAvailableStrokeOpacity:string = this.config.configurationFileDVM.seatAvailableStrokeOpacity ? this.config.configurationFileDVM.seatAvailableStrokeOpacity : "";
  seatAvailableLineWidth:string = this.config.configurationFileDVM.seatAvailableLineWidth ? this.config.configurationFileDVM.seatAvailableLineWidth : "";

  seatAvailableHover:string = this.config.configurationFileDVM.seatAvailableHover ? this.config.configurationFileDVM.seatAvailableHover : "";
  seatAvailableHoverOpacity:string = this.config.configurationFileDVM.seatAvailableHoverOpacity ? this.config.configurationFileDVM.seatAvailableHoverOpacity : "";
  seatAvailableHoverStroke:string = this.config.configurationFileDVM.seatAvailableHoverStroke ? this.config.configurationFileDVM.seatAvailableHoverStroke : "";
  seatAvailableHoverStrokeOpacity:string = this.config.configurationFileDVM.seatAvailableHoverStrokeOpacity ? this.config.configurationFileDVM.seatAvailableHoverStrokeOpacity : "";
  seatAvailableHoverLineWidth:string = this.config.configurationFileDVM.seatAvailableHoverLineWidth ? this.config.configurationFileDVM.seatAvailableHoverLineWidth : "";
  
  seatSelected:string = this.config.configurationFileDVM.seatSelected ? this.config.configurationFileDVM.seatSelected : "";
  seatSelectedOpacity:string = this.config.configurationFileDVM.seatSelectedOpacity ? this.config.configurationFileDVM.seatSelectedOpacity : "";
  seatSelectedStroke:string = this.config.configurationFileDVM.seatSelectedStroke ? this.config.configurationFileDVM.seatSelectedStroke : "";
  seatSelectedStrokeOpacity:string = this.config.configurationFileDVM.seatSelectedStrokeOpacity ? this.config.configurationFileDVM.seatSelectedStrokeOpacity : "";
  seatSelectedLineWidth:string = this.config.configurationFileDVM.seatSelectedLineWidth ? this.config.configurationFileDVM.seatSelectedLineWidth : "";

  seatSelectedHover:string = this.config.configurationFileDVM.seatSelectedHover ? this.config.configurationFileDVM.seatSelectedHover : "";
  seatSelectedHoverOpacity:string = this.config.configurationFileDVM.seatSelectedHoverOpacity ? this.config.configurationFileDVM.seatSelectedHoverOpacity : "";
  seatSelectedHoverStroke:string = this.config.configurationFileDVM.seatSelectedHoverStroke ? this.config.configurationFileDVM.seatSelectedHoverStroke : "";
  seatSelectedHoverStrokeOpacity:string = this.config.configurationFileDVM.seatSelectedHoverStrokeOpacity ? this.config.configurationFileDVM.seatSelectedHoverStrokeOpacity : "";
  seatSelectedHoverLineWidth:string = this.config.configurationFileDVM.seatSelectedHoverLineWidth ? this.config.configurationFileDVM.seatSelectedHoverLineWidth : "";

  seatUnavailable:string = this.config.configurationFileDVM.seatUnavailable ? this.config.configurationFileDVM.seatUnavailable : "";
  seatUnavailableOpacity:string = this.config.configurationFileDVM.seatUnavailableOpacity ? this.config.configurationFileDVM.seatUnavailableOpacity : "";

  resetValues(){
    this.blockAvailable = "";
    this.blockAvailableOpacity = "";
    this.blockAvailableStroke = "";
    this.blockAvailableStrokeOpacity = "";
    this.blockAvailableLineWidth = "";
  
    this.blockAvailableHover = "";
    this.blockAvailableHoverOpacity = "";
    this.blockAvailableHoverStroke = "";
    this.blockAvailableHoverStrokeOpacity = "";
    this.blockAvailableHoverLineWidth = "";
    
    this.blockSelected = "";
    this.blockSelectedOpacity = "";
    this.blockSelectedStroke =  "";
    this.blockSelectedStrokeOpacity = "";
    this.blockSelectedLineWidth = "";
  
    this.blockSelectedHover = "";
    this.blockSelectedHoverOpacity = "";
    this.blockSelectedHoverStroke = "";
    this.blockSelectedHoverStrokeOpacity = "";
    this.blockSelectedHoverLineWidth = "";
  
    this.blockUnavailable = "";
    this.blockUnavailableOpacity = "";
    this.blockUnavailableStroke = "";
    this.blockUnavailableStrokeOpacity = "";

    this.blockFiltered = "";
    this.blockFilteredOpacity = "";
    this.blockFilteredHover = "";
    this.blockFilteredHoverOpacity = "";
    this.blockFilteredUnavailable = "";
    this.blockFilteredUnavailableOpacity = "";

    this.blockFilteredStroke = "";
    this.blockFilteredStrokeOpacity = "";
    this.blockFilteredHoverStroke = "";
    this.blockFilteredHoverStrokeOpacity = "";
    this.blockFilteredUnavailableStroke = "";
    this.blockFilteredUnavailableStrokeOpacity = "";
    this.blockFilteredHoverLineWidth = "";
    this.blockFilteredLineWidth = "";
  }

  fillAll() {
    this.blockAvailable = "#fff";
    this.blockAvailableOpacity = "0.1";
    this.blockAvailableStroke = "#fff";
    this.blockAvailableStrokeOpacity = "0.6";
    this.blockAvailableLineWidth = "1";
  
    this.blockAvailableHover = "#fff";
    this.blockAvailableHoverOpacity = "0.8";
    this.blockAvailableHoverStroke = "#fff";
    this.blockAvailableHoverStrokeOpacity = "1";
    this.blockAvailableHoverLineWidth = "1";
    
    this.blockSelected = "#1f8efa";
    this.blockSelectedOpacity = "1";
    this.blockSelectedStroke =  "#fff";
    this.blockSelectedStrokeOpacity = "1";
    this.blockSelectedLineWidth = "1";
  
    this.blockSelectedHover = "#cce6ff";
    this.blockSelectedHoverOpacity = "0.8";
    this.blockSelectedHoverStroke = "#1f8efa";
    this.blockSelectedHoverStrokeOpacity = "1";
    this.blockSelectedHoverLineWidth = "1";
  
    this.blockUnavailable = "#000";
    this.blockUnavailableOpacity = "0.4";
    this.blockUnavailableStroke = "#000";
    this.blockUnavailableStrokeOpacity = "0.4";

    this.blockFiltered = "#fff";
    this.blockFilteredOpacity = "0.7";
    this.blockFilteredHover = "#fff";
    this.blockFilteredHoverOpacity = "1";
    this.blockFilteredUnavailable = "#000";
    this.blockFilteredUnavailableOpacity = "0.4";

    this.blockFilteredStroke = "#fff";
    this.blockFilteredStrokeOpacity = "0.7";
    this.blockFilteredHoverStroke = "#fff";
    this.blockFilteredHoverStrokeOpacity = "1";
    this.blockFilteredUnavailableStroke = "#000";
    this.blockFilteredUnavailableStrokeOpacity = "0.4";

    this.blockFilteredHoverLineWidth = "1";
    this.blockFilteredLineWidth = "1";
  }

  resetValuesSeat(){
    this.seatAvailable = "";
    this.seatAvailableOpacity = "";
    this.seatAvailableStroke = "";
    this.seatAvailableStrokeOpacity = "";
    this.seatAvailableLineWidth = "";
  
    this.seatAvailableHover = "";
    this.seatAvailableHoverOpacity = "";
    this.seatAvailableHoverStroke = "";
    this.seatAvailableHoverStrokeOpacity = "";
    this.seatAvailableHoverLineWidth = "";
    
    this.seatSelected = "";
    this.seatSelectedOpacity = "";
    this.seatSelectedStroke =  "";
    this.seatSelectedStrokeOpacity = "";
    this.seatSelectedLineWidth = "";
  
    this.seatSelectedHover = "";
    this.seatSelectedHoverOpacity = "";
    this.seatSelectedHoverStroke = "";
    this.seatSelectedHoverStrokeOpacity = "";
    this.seatSelectedHoverLineWidth = "";
  
    this.seatUnavailable = "";
    this.seatUnavailableOpacity = "";
  }

  fillAllSeat() {
    this.seatAvailable = "#36b369";
    this.seatAvailableOpacity = "0.7";
    this.seatAvailableStroke = "#198042";
    this.seatAvailableStrokeOpacity = "0.7";
    this.seatAvailableLineWidth = "0.05";
  
    this.seatAvailableHover = "#CCFFE1";
    this.seatAvailableHoverOpacity = "0.7";
    this.seatAvailableHoverStroke = "#36B369";
    this.seatAvailableHoverStrokeOpacity = "0.7";
    this.seatAvailableHoverLineWidth = "0.05";
    
    this.seatSelected = "#FF4D4D";
    this.seatSelectedOpacity = "0.7";
    this.seatSelectedStroke = "#801A1A";
    this.seatSelectedStrokeOpacity = "0.7";
    this.seatSelectedLineWidth = "0.05";
  
    this.seatSelectedHover = "#FFCCCC";
    this.seatSelectedHoverOpacity = "0.7";
    this.seatSelectedHoverStroke = "#FF4D4D";
    this.seatSelectedHoverStrokeOpacity = "0.7";
    this.seatSelectedHoverLineWidth = "0.05";
  
    this.seatUnavailable = "#fff";
    this.seatUnavailableOpacity = "0";
  }

  validateData() {
    this.config.configurationFileDVM.bgHeader = this.bgHeader;
    this.config.configurationFileDVM.txtHeader = this.txtHeader;
    this.config.configurationFileDVM.bgTopbar = this.bgTopbar;
    this.config.configurationFileDVM.ticketLab = this.ticketLab;
    this.config.configurationFileDVM.ticketVal = this.ticketVal;
    this.config.configurationFileDVM.bgPopover = this.bgPopover;
    this.config.configurationFileDVM.popoverLab = this.popoverLab;
    this.config.configurationFileDVM.popoverVal = this.popoverVal;
    this.config.configurationFileDVM.bgButton = this.bgButton;
    this.config.configurationFileDVM.buttonLab = this.buttonLab;

    this.config.configurationFileDVM.blockAvailable = this.blockAvailable;
    this.config.configurationFileDVM.blockAvailableOpacity = this.blockAvailableOpacity;
    this.config.configurationFileDVM.blockAvailableStroke = this.blockAvailableStroke;
    this.config.configurationFileDVM.blockAvailableStrokeOpacity = this.blockAvailableStrokeOpacity;
    this.config.configurationFileDVM.blockAvailableLineWidth = this.blockAvailableLineWidth;
  
    this.config.configurationFileDVM.blockAvailableHover = this.blockAvailableHover;
    this.config.configurationFileDVM.blockAvailableHoverOpacity = this.blockAvailableHoverOpacity;
    this.config.configurationFileDVM.blockAvailableHoverStroke = this.blockAvailableHoverStroke;
    this.config.configurationFileDVM.blockAvailableHoverStrokeOpacity = this.blockAvailableHoverStrokeOpacity;
    this.config.configurationFileDVM.blockAvailableHoverLineWidth = this.blockAvailableHoverLineWidth;
    
    this.config.configurationFileDVM.blockSelected = this.blockSelected;
    this.config.configurationFileDVM.blockSelectedOpacity = this.blockSelectedOpacity;
    this.config.configurationFileDVM.blockSelectedStroke = this.blockSelectedStroke;
    this.config.configurationFileDVM.blockSelectedStrokeOpacity = this.blockSelectedStrokeOpacity;
    this.config.configurationFileDVM.blockSelectedLineWidth = this.blockSelectedLineWidth;
  
    this.config.configurationFileDVM.blockSelectedHover = this.blockSelectedHover;
    this.config.configurationFileDVM.blockSelectedHoverOpacity = this.blockSelectedHoverOpacity;
    this.config.configurationFileDVM.blockSelectedHoverStroke = this.blockSelectedHoverStroke;
    this.config.configurationFileDVM.blockSelectedHoverStrokeOpacity = this.blockSelectedHoverStrokeOpacity;
    this.config.configurationFileDVM.blockSelectedHoverLineWidth = this.blockSelectedHoverLineWidth;
  
    this.config.configurationFileDVM.blockUnavailable = this.blockUnavailable;
    this.config.configurationFileDVM.blockUnavailableOpacity = this.blockUnavailableOpacity;
    this.config.configurationFileDVM.blockUnavailableStroke = this.blockUnavailableStroke;
    this.config.configurationFileDVM.blockUnavailableStrokeOpacity = this.blockUnavailableStrokeOpacity;

    this.config.configurationFileDVM.blockFiltered = this.blockFiltered;
    this.config.configurationFileDVM.blockFilteredOpacity = this.blockFilteredOpacity;
    this.config.configurationFileDVM.blockFilteredHover = this.blockFilteredHover;
    this.config.configurationFileDVM.blockFilteredHoverOpacity = this.blockFilteredHoverOpacity;
    this.config.configurationFileDVM.blockFilteredUnavailable = this.blockFilteredUnavailable;
    this.config.configurationFileDVM.blockFilteredUnavailableOpacity = this.blockFilteredUnavailableOpacity;

    this.config.configurationFileDVM.blockFilteredStroke = this.blockFilteredStroke;
    this.config.configurationFileDVM.blockFilteredStrokeOpacity = this.blockFilteredStrokeOpacity;
    this.config.configurationFileDVM.blockFilteredHoverStroke = this.blockFilteredHoverStroke;
    this.config.configurationFileDVM.blockFilteredHoverStrokeOpacity = this.blockFilteredHoverStrokeOpacity;
    this.config.configurationFileDVM.blockFilteredUnavailableStroke = this.blockFilteredUnavailableStroke;
    this.config.configurationFileDVM.blockFilteredUnavailableStrokeOpacity = this.blockFilteredUnavailableStrokeOpacity;

    this.config.configurationFileDVM.blockFilteredHoverLineWidth = this.blockFilteredHoverLineWidth;
    this.config.configurationFileDVM.blockFilteredLineWidth = this.blockFilteredLineWidth;
  
  
    this.config.configurationFileDVM.seatAvailable = this.seatAvailable;
    this.config.configurationFileDVM.seatAvailableOpacity = this.seatAvailableOpacity;
    this.config.configurationFileDVM.seatAvailableStroke = this.seatAvailableStroke;
    this.config.configurationFileDVM.seatAvailableStrokeOpacity = this.seatAvailableStrokeOpacity
    this.config.configurationFileDVM.seatAvailableLineWidth = this.seatAvailableLineWidth;
  
    this.config.configurationFileDVM.seatAvailableHover = this.seatAvailableHover;
    this.config.configurationFileDVM.seatAvailableHoverOpacity = this.seatAvailableHoverOpacity;
    this.config.configurationFileDVM.seatAvailableHoverStroke = this.seatAvailableHoverStroke;
    this.config.configurationFileDVM.seatAvailableHoverStrokeOpacity = this.seatAvailableHoverStrokeOpacity;
    this.config.configurationFileDVM.seatAvailableHoverLineWidth = this.seatAvailableHoverLineWidth;
    
    this.config.configurationFileDVM.seatSelected = this.seatSelected;
    this.config.configurationFileDVM.seatSelectedOpacity = this.seatSelectedOpacity;
    this.config.configurationFileDVM.seatSelectedStroke = this.seatSelectedStroke;
    this.config.configurationFileDVM.seatSelectedStrokeOpacity = this.seatSelectedStrokeOpacity;
    this.config.configurationFileDVM.seatSelectedLineWidth = this.seatSelectedLineWidth;
  
    this.config.configurationFileDVM.seatSelectedHover = this.seatSelectedHover;
    this.config.configurationFileDVM.seatSelectedHoverOpacity = this.seatSelectedHoverOpacity;
    this.config.configurationFileDVM.seatSelectedHoverStroke = this.seatSelectedHoverStroke;
    this.config.configurationFileDVM.seatSelectedHoverStrokeOpacity = this.seatSelectedHoverStrokeOpacity;
    this.config.configurationFileDVM.seatSelectedHoverLineWidth = this.seatSelectedHoverLineWidth;
  
    this.config.configurationFileDVM.seatUnavailable = this.seatUnavailable;
    this.config.configurationFileDVM.seatUnavailableOpacity = this.seatUnavailableOpacity;

    this.config.breadcrumbs.push({name:"Files", state:"common.filesdvm"});
    this.state.go('common.filesdvm');
  }

  constructor(private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) { }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    }
  }

}

import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../../services/global-functions'
declare var $:any;


@Injectable()
export class NeighbourClickAppHandler extends Handler {
  constructor() {
    super();
    this.handling['seatmap'] = ['clickNeighbor'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    console.log(message)
    console.log(status);
  }
}

import { Injectable } from '@angular/core';
import { TK3DMinisiteConfiguration, DVMMinisiteConfiguration } from '../minisite.configuration';


@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    public minimapModule:any;

    public breadcrumbs = [];
    public configurationFile: TK3DMinisiteConfiguration = {
        flag: "TK3D",
        minisiteUrl: "",
        mapID: "",
        minimapID:"",
        venueID: "",
        venueName: "",
        subtitleName: "",
        viewType: "seat",
        viewerIntegration:"old",
        exceptions: [""],
        ticketsUrl: "",
        logoUrl: "",
        ticketsName: "",
        hasMinimap: true,
        minimapZoom:1,
        hasPano: true,
        filters: false,
        navigation: false,
        searchbar: true,
        tabs: false,
        tabsManual:false,
        neighbours:false,
        leftRight: false,
        unavailable: false,
        translation: false,
        buyTickets: true,
        infoMenuCheck: false,
        infoMenuTitle: "",
        infoMenuOptions: [],
        logoType: "",
        faviconType:"",
        logo:true,
        bg:true,
        favicon:true,
        backgroundColor:"",
        exceptionsJsonCheck: false,
        exceptionsJson: [],
        popupJsonCheck: false,
        popupJson: [],
        popupImg: "img",
        bgHeader: "",
        txtHeader: "",
        bgTopbar:"",
        ticketLab: "",
        ticketVal: "",
        bgPopover: "",
        popoverLab: "",
        popoverVal: "",
        bgButton: "",
        buttonLab: "",
        copyLinkCheck: true,

        blockOpacity:"",
        seatOpacity:"",

        blockAvailable:"",
        blockAvailableOpacity:"",
        blockAvailableHover:"",
        blockAvailableHoverOpacity:"",
        blockSelected:"",
        blockSelectedOpacity:"",
        blockSelectedHover:"",
        blockSelectedHoverOpacity:"",
        blockUnavailable:"",
        blockUnavailableOpacity:"",
        blockUnavailableStroke:"",
        blockUnavailableStrokeOpacity:"",
        blockFiltered: "",
        blockFilteredOpacity: "",
        blockFilteredHover:"",
        blockFilteredHoverOpacity:"",
        blockFilteredUnavailable: "",
        blockFilteredUnavailableOpacity: "",
        blockAvailableStroke:"",
        blockAvailableStrokeOpacity:"",
        blockAvailableHoverStroke:"",
        blockAvailableHoverStrokeOpacity:"",
        blockSelectedStroke:"",
        blockSelectedStrokeOpacity:"",
        blockSelectedHoverStroke:"",
        blockSelectedHoverStrokeOpacity:"",
        blockFilteredStroke:"",
        blockFilteredStrokeOpacity:"",
        blockFilteredHoverStroke:"",
        blockFilteredHoverStrokeOpacity:"",
        blockFilteredUnavailableStroke:"",
        blockFilteredUnavailableStrokeOpacity:"",

        seatAvailable:"",
        seatAvailableOpacity:"",
        seatAvailableHover:"",
        seatAvailableHoverOpacity:"",
        seatSelected:"",
        seatSelectedOpacity:"",
        seatSelectedHover:"",
        seatSelectedHoverOpacity:"",
        seatUnavailable:"",
        seatUnavailableOpacity:"",
        viewerVersion:"",
        onlyViewer:""
    }

    public logo: any;
    public background: any;
    public logoPreview: any;
    public bgPreview: any;
    public favicon: any;
    public favPreview: any;

    public tabsCheck: boolean;
    public filtersCheck: boolean;
    public unavailableCheck: boolean;

    public filteringJson: any;
    public seatmapNavigationJson: any;
    public blocksUnavailableJson: any;
    public seatsUnavailableJson: any;
    public navigationJson: any;
    public tabOptionsJson:any;
    public infomenuJson:any;
    public neighboursJson:any;
    public popupJson: any;
    public exceptionsJson: any;
    public unavailableOptionsJson: any;
    public translationOptionsJson: any;
    public neighboursOptionsJson: any;
    
    public filteringJsonPre: any;
    public seatmapNavigationJsonPre: any;
    public blocksUnavailableJsonPre: any;
    public seatsUnavailableJsonPre: any;
    public navigationJsonPre: any;
    public tabOptionsJsonPre: any;
    public neighboursOptionsJsonPre: any;
    public infomenuJsonPre:any;
    public popupJsonPre: any;
    public exceptionsJsonPre: any;
    public unavailableOptionsJsonPre: any;
    public translationOptionsJsonPre: any;

    public configurationFileDVM: DVMMinisiteConfiguration = {
        flag: "DVM",
        integrationType: "",
        libraryVersion:"",
        viewerIntegration:"",
        minisiteUrl: "",
        mapID: "",
        venueID: "",
        minimapID: "",
        venueName: "",
        subtitleName: "",
        viewType: "seat",
        hasPano: true,
        hasMinimap: true,
        exceptions: [],
        ticketsUrl: "",
        logoUrl: "",
        logoType: "",
        faviconType:"",
        ticketsName: "",
        filters: false,
        navigation: false,
        tabs: false,
        tabsManual:false,
        neighbours:false,
        leftRight: false,
        searchbar: true,
        translation: false,
        buyTickets: true,
        logo:true,
        bg:true,
        favicon:true,
        backgroundColor:"",
        unavailable: false,
        navigationPluginCheck:false,
        indoorPluginCheck:false,
        galleryPluginCheck:false,
        bestNodesCheck: false,
        infoMenuCheck: false,
        infoMenuTitle: "",
        popupJsonCheck: false,
        exceptionsJsonCheck: false,
        multiIndoorCheck: false,
        exceptionsJson: [],
        popupJson: [],
        popupImg: "img",
        bgHeader: "",
        txtHeader: "",
        bgTopbar:"",
        ticketLab: "",
        ticketVal: "",
        bgPopover: "",
        popoverLab: "",
        popoverVal: "",
        bgButton: "",
        buttonLab: "",
        copyLinkCheck: true,

        blockAvailable:"",
        blockAvailableOpacity:"",
        blockAvailableStroke:"",
        blockAvailableStrokeOpacity:"",
        blockAvailableLineWidth:"",
        blockAvailableHover:"",
        blockAvailableHoverOpacity:"",
        blockAvailableHoverStroke:"",
        blockAvailableHoverStrokeOpacity:"",
        blockAvailableHoverLineWidth:"",
        blockSelected:"",
        blockSelectedOpacity:"",
        blockSelectedStroke:"",
        blockSelectedStrokeOpacity:"",
        blockSelectedLineWidth:"",
        blockSelectedHover:"",
        blockSelectedHoverOpacity:"",
        blockSelectedHoverStroke:"",
        blockSelectedHoverStrokeOpacity:"",
        blockSelectedHoverLineWidth:"",
        blockUnavailable:"",
        blockUnavailableOpacity:"",
        blockUnavailableStroke:"",
        blockUnavailableStrokeOpacity:"",
        blockFiltered: "",
        blockFilteredOpacity: "",
        blockFilteredHover:"",
        blockFilteredHoverOpacity:"",
        blockFilteredUnavailable: "",
        blockFilteredUnavailableOpacity: "",
        blockFilteredStroke:"",
        blockFilteredStrokeOpacity:"",
        blockFilteredHoverStroke:"",
        blockFilteredHoverStrokeOpacity:"",
        blockFilteredUnavailableStroke:"",
        blockFilteredUnavailableStrokeOpacity:"",
        blockFilteredLineWidth:"",
        blockFilteredHoverLineWidth:"",

        seatAvailable:"",
        seatAvailableOpacity:"",
        seatAvailableStroke:"",
        seatAvailableStrokeOpacity:"",
        seatAvailableLineWidth:"",
        seatAvailableHover:"",
        seatAvailableHoverOpacity:"",
        seatAvailableHoverStroke:"",
        seatAvailableHoverStrokeOpacity:"",
        seatAvailableHoverLineWidth:"",
        seatSelected:"",
        seatSelectedOpacity:"",
        seatSelectedStroke:"",
        seatSelectedStrokeOpacity:"",
        seatSelectedLineWidth:"",
        seatSelectedHover:"",
        seatSelectedHoverOpacity:"",
        seatSelectedHoverStroke:"",
        seatSelectedHoverStrokeOpacity:"",
        seatSelectedHoverLineWidth:"",
        seatUnavailable:"",
        seatUnavailableOpacity:"",
        viewerVersion:"",
        onlyViewer:""
    }

    constructor() { }

    getMinisiteConfig() {
        return this.configurationFile;
    }
}

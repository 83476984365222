import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss']
})
export class InfoTableComponent implements OnInit {
  constructor(private modalService: BsModalService, private config: ConfigurationService) { }

  @Input() infoTable;
  @Input() indexTable;

  localJsonTabs = this.config.configurationFileDVM.infoMenuOptions;

  modalRef: BsModalRef;
  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: ' modal-dialog-centered'
  };

  // infomenu array & values
  infoVenueID;
  infoMapID;
  infoMenuTitle;
  campo: string;
  valor: string;
  index: number;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  createOption() {
    if(this.campo != undefined && this.valor != undefined) {
        let l = this.infoTable.list.length;
    
        var o = {
            index: l,
            campo: this.campo,
            valor: this.valor
        };
    
        this.infoTable.list.push(o);
        this.infoTable.title = this.infoMenuTitle;
        this.index = null;
        this.campo = "";
        this.valor = "";
        this.modalRef.hide();
    }
  }

  createOptionEd() {
    this.infoTable.list[$("#index").val()].campo = $("#campo").val();
    this.infoTable.list[$("#index").val()].valor = $("#valor").val();
    this.index = null;
    this.campo = "";    
    this.valor = "";    
    this.modalRef.hide();
  }

  editOption(template: TemplateRef<any>, opt, index) {
    var initialState = {index: index, campo: opt['campo'], valor: opt['valor']};
    this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});
    this.campo = opt['campo'];
    this.valor = opt['valor'];
    this.index = index;
    this.modalRef.content = initialState;
  }

  deleteOption(o) {
    this.infoTable.list.splice(o, 1);
    for(var i = o; i < this.infoTable.list.length; i++) {
      this.infoTable.list[i].index = this.infoTable.list[i].index - 1;
    }
  }

  closeModal(){
    this.index = null;
    this.campo = "";    
    this.valor = "";  
    this.modalRef.hide();
  }

  get menuoptions() {
    return this.infoTable.list;
  }

  moveTableElement(element, index){
    var row = $(element.path[2]).closest('tr');

    if ($(element.path[0]).hasClass('up')) {
      if(index !== 0) {
        row.prev().before(row);
        this.infoTable.list[index].index = index - 1;
        this.infoTable.list[index - 1].index = index;
      }
    } else {
      if(index+1 !== this.infoTable.list.length) {
        row.next().after(row);
        this.infoTable.list[index].index = index + 1;
        this.infoTable.list[index + 1].index = index;
      }
    }

    this.infoTable.list.sort(this.compareValues('index'));
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
  
      const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  ngOnInit() {
    console.log(this.config)
    this.infoTable["list"] = [];
    this.infoTable["title"] = "";
    // delete this.infoTable.active;
    // delete this.infoTable.idColor;
    // delete this.infoTable.image;
    // delete this.infoTable.minimapid;
    // delete this.infoTable.name;
    // delete this.infoTable.textColor;
    // delete this.infoTable.topbarColor;
    this.infoVenueID = this.infoTable.venueid;
    this.infoMapID = this.infoTable.mapid;

    // if(this.localJsonTabs){
    //   for(var i in this.localJsonTabs) {
    //     if(this.localJsonTabs[i].venueid === this.infoTable.venueid && this.localJsonTabs[i].mapid === this.infoTable.mapid) {
    //       this.infoTable.list = this.localJsonTabs[i].list;
    //       this.infoMenuTitle = this.localJsonTabs[i].title;
    //     }
    //   }
    // }
  }

}

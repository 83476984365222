import { Component, OnInit, Input } from '@angular/core';
import { Tk3dService, GeneralState } from 'ngx-tk3d';
import { ConfigurationService } from 'src/app/services/configuration.service';
declare var $:any;


@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.scss']
})
export class MainInterfaceComponent implements OnInit {

    @Input() state: GeneralState;
    @Input() selectedSeats: Object[];

    filtersTrue = this.config.configurationFile.filters;

    isStep1() {
        return this.status.blockmap.visible && this.status.blockmap.size === 'large';
    }

    isStep2() {
        return this.status.seatmap.visible && this.status.seatmap.size === 'large';
    }

    isStep3 () {
        return this.status.view3d.visible;
    }

    public tk3dBack() {
        if (this.status.view3d.visible) {
            if(this.config.configurationFile.viewType === "section") {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.status.blockmap.selected as any) > -1) {
                    this.tk3d.onSeatMapMiniClick();
                } else {
                    this.tk3d.onBlockMapMiniClick();
                }
            } else {
                if(this.config.configurationFile.exceptions && this.config.configurationFile.exceptions.indexOf(this.status.blockmap.selected as any) > -1) {
                    this.tk3d.onBlockMapMiniClick();
                } else {
                    this.tk3d.onSeatMapMiniClick();
                }
            } 
        } else if(this.status.seatmap.visible){
            //$('#seatmap').height(0);
            this.tk3d.onBlockMapMiniClick();
        }
    }

    public goHome() {
        this.tk3d.onBlockMapMiniClick();
    }


    get status(): GeneralState {
        return this.tk3d.tk3dStatus;
    }

    get sector() {
        return (<string>this.state.blockmap.selected).split('_')[1];
    }

    get seat() {
        if (this.state.seatmap.selected.length === 0) { return undefined; }
        return this.state.seatmap.selected[0];
    }

    public isTicketSelectedVisible() {
        return this.state.blockmap.selected || this.state.seatmap.selected.length > 0;
    }

    public isMobile(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
        }
        else{
        return false;
        }
    }

    toggleFullscreen() {
        this.state.view3d.moduleRef.toggleFullscreen();

        $("#ticketing3dwidget-fullscreen-button0").addClass("d-none");
        
        if(!$("#ticketing3dwidget-fullscreen-button0").hasClass("onfullscreen-tk3d")) {
            $("#ticketing3dwidget0").append($(".fsclose-btn-tk3d").removeClass("d-none"))
        } else {
            $(".fsclose-btn-tk3d").addClass("d-none");
        }
    }

    constructor( private tk3d: Tk3dService, private config: ConfigurationService) { }

    ngOnInit() {
    }

    ngAfterViewChecked() {
        if(!this.isMobile()){
            document.getElementById('main-interface').style.height = (document.getElementById('main-interface').offsetWidth/1.764)+'px';
        }
        
        document.documentElement.style.setProperty('--block-available', this.config.configurationFile.blockAvailable);
        document.documentElement.style.setProperty('--block-available-opacity', this.config.configurationFile.blockAvailableOpacity);
        document.documentElement.style.setProperty('--block-available-hover', this.config.configurationFile.blockAvailableHover);
        document.documentElement.style.setProperty('--block-available-hover-opacity', this.config.configurationFile.blockAvailableHoverOpacity);
        document.documentElement.style.setProperty('--block-selected', this.config.configurationFile.blockSelected);
        document.documentElement.style.setProperty('--block-selected-opacity', this.config.configurationFile.blockSelectedOpacity);
        document.documentElement.style.setProperty('--block-selected-hover', this.config.configurationFile.blockSelectedHover);
        document.documentElement.style.setProperty('--block-selected-hover-opacity', this.config.configurationFile.blockSelectedHoverOpacity);

        document.documentElement.style.setProperty('--block-unavailable', this.config.configurationFile.blockUnavailable);
        document.documentElement.style.setProperty('--block-unavailable-opacity', this.config.configurationFile.blockUnavailableOpacity);

        document.documentElement.style.setProperty('--seat-available', this.config.configurationFile.seatAvailable);
        document.documentElement.style.setProperty('--seat-available-opacity', this.config.configurationFile.seatAvailableOpacity);
        document.documentElement.style.setProperty('--seat-available-hover', this.config.configurationFile.seatAvailableHover);
        document.documentElement.style.setProperty('--seat-available-hover-opacity', this.config.configurationFile.seatAvailableHoverOpacity);
        document.documentElement.style.setProperty('--seat-selected', this.config.configurationFile.seatSelected);
        document.documentElement.style.setProperty('--seat-selected-opacity', this.config.configurationFile.seatSelectedOpacity);
        document.documentElement.style.setProperty('--seat-selected-hover', this.config.configurationFile.seatSelectedHover);
        document.documentElement.style.setProperty('--seat-selected-hover-opacity', this.config.configurationFile.seatSelectedHoverOpacity);

        document.documentElement.style.setProperty('--seat-unavailable', this.config.configurationFile.seatUnavailable);
        document.documentElement.style.setProperty('--seat-unavailable-opacity', this.config.configurationFile.seatUnavailableOpacity);

    }

}

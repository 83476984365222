import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { StateService } from '@uirouter/core';
declare var $: any;
import * as global from '../../services/global-functions'


@Component({
  selector: 'app-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnInit {
  buyTickets: boolean = this.config.configurationFile.buyTickets;
  
  bgHeader: string = this.config.configurationFile.bgHeader ? this.config.configurationFile.bgHeader : "";
  txtHeader: string = this.config.configurationFile.txtHeader ? this.config.configurationFile.txtHeader : "";
  bgTopbar: string = this.config.configurationFile.bgTopbar ? this.config.configurationFile.bgTopbar : "";
  ticketLab: string = this.config.configurationFile.ticketLab ? this.config.configurationFile.ticketLab : "";
  ticketVal: string = this.config.configurationFile.ticketVal ? this.config.configurationFile.ticketVal : "";
  bgPopover: string = this.config.configurationFile.bgPopover ? this.config.configurationFile.bgPopover : "";
  popoverLab: string = this.config.configurationFile.popoverLab ? this.config.configurationFile.popoverLab : "";
  popoverVal: string = this.config.configurationFile.popoverVal ? this.config.configurationFile.popoverVal : "";
  bgButton: string = this.config.configurationFile.bgButton ? this.config.configurationFile.bgButton : "";
  buttonLab: string = this.config.configurationFile.buttonLab ? this.config.configurationFile.buttonLab : "";

  blockAvailable:string = this.config.configurationFile.blockAvailable ? this.config.configurationFile.blockAvailable : "";
  blockAvailableOpacity:string = this.config.configurationFile.blockAvailableOpacity ? this.config.configurationFile.blockAvailableOpacity : "";
  blockAvailableHover:string = this.config.configurationFile.blockAvailableHover ? this.config.configurationFile.blockAvailableHover : "";
  blockAvailableHoverOpacity:string = this.config.configurationFile.blockAvailableHoverOpacity ? this.config.configurationFile.blockAvailableHoverOpacity : "";
  blockUnavailable:string = this.config.configurationFile.blockUnavailable ? this.config.configurationFile.blockUnavailable : "";
  blockUnavailableOpacity:string = this.config.configurationFile.blockUnavailableOpacity ? this.config.configurationFile.blockUnavailableOpacity : "";
  blockUnavailableStroke:string = this.config.configurationFile.blockUnavailableStroke ? this.config.configurationFile.blockUnavailableStroke : "";
  blockUnavailableStrokeOpacity:string = this.config.configurationFile.blockUnavailableStrokeOpacity ? this.config.configurationFile.blockUnavailableStrokeOpacity : "";
  blockSelected:string = this.config.configurationFile.blockSelected ? this.config.configurationFile.blockSelected : "";
  blockSelectedOpacity:string = this.config.configurationFile.blockSelectedOpacity ? this.config.configurationFile.blockSelectedOpacity : "";
  blockSelectedHover:string = this.config.configurationFile.blockSelectedHover ? this.config.configurationFile.blockSelectedHover : "";
  blockSelectedHoverOpacity:string = this.config.configurationFile.blockSelectedHoverOpacity ? this.config.configurationFile.blockSelectedHoverOpacity : "";
  blockFiltered:string = this.config.configurationFile.blockFiltered ? this.config.configurationFile.blockFiltered : "";
  blockFilteredOpacity: string = this.config.configurationFile.blockFilteredOpacity ? this.config.configurationFile.blockFilteredOpacity : "";
  blockFilteredHover: string =this.config.configurationFile.blockFilteredHover ? this.config.configurationFile.blockFilteredHover : "";
  blockFilteredHoverOpacity: string = this.config.configurationFile.blockFilteredHoverOpacity ? this.config.configurationFile.blockFilteredHoverOpacity : "";
  blockFilteredUnavailable: string = this.config.configurationFile.blockFilteredUnavailable ? this.config.configurationFile.blockFilteredUnavailable : "";
  blockFilteredUnavailableOpacity: string = this.config.configurationFile.blockFilteredUnavailableOpacity ? this.config.configurationFile.blockFilteredUnavailableOpacity : "";

  blockAvailableStroke:string = this.config.configurationFile.blockAvailableStroke ? this.config.configurationFile.blockAvailableStroke : "";
  blockAvailableStrokeOpacity:string = this.config.configurationFile.blockAvailableStrokeOpacity ? this.config.configurationFile.blockAvailableStrokeOpacity : "";
  blockAvailableHoverStroke:string = this.config.configurationFile.blockAvailableHoverStroke ? this.config.configurationFile.blockAvailableHoverStroke : "";
  blockAvailableHoverStrokeOpacity:string = this.config.configurationFile.blockAvailableHoverStrokeOpacity ? this.config.configurationFile.blockAvailableHoverStrokeOpacity : "";
  blockSelectedStroke:string = this.config.configurationFile.blockSelectedStroke ? this.config.configurationFile.blockSelectedStroke : "";
  blockSelectedStrokeOpacity:string = this.config.configurationFile.blockSelectedStrokeOpacity ? this.config.configurationFile.blockSelectedStrokeOpacity : "";
  blockSelectedHoverStroke:string = this.config.configurationFile.blockSelectedHoverStroke ? this.config.configurationFile.blockSelectedHoverStroke : "";
  blockSelectedHoverStrokeOpacity:string = this.config.configurationFile.blockSelectedHoverStrokeOpacity ? this.config.configurationFile.blockSelectedHoverStrokeOpacity : "";
  blockFilteredStroke:string = this.config.configurationFile.blockFilteredStroke ? this.config.configurationFile.blockFilteredStroke : "";
  blockFilteredStrokeOpacity:string = this.config.configurationFile.blockFilteredStrokeOpacity ? this.config.configurationFile.blockFilteredStrokeOpacity : "";
  blockFilteredHoverStroke:string = this.config.configurationFile.blockFilteredHoverStroke ? this.config.configurationFile.blockFilteredHoverStroke : "";
  blockFilteredHoverStrokeOpacity:string = this.config.configurationFile.blockFilteredHoverStrokeOpacity ? this.config.configurationFile.blockFilteredHoverStrokeOpacity : "";
  blockFilteredUnavailableStroke:string = this.config.configurationFile.blockFilteredUnavailableStroke ? this.config.configurationFile.blockFilteredUnavailableStroke : "";
  blockFilteredUnavailableStrokeOpacity:string = this.config.configurationFile.blockFilteredUnavailableStrokeOpacity ? this.config.configurationFile.blockFilteredUnavailableStrokeOpacity : "";

  blockOpacity:string = this.config.configurationFile.blockOpacity ? this.config.configurationFile.blockOpacity : "";

  seatAvailable:string = this.config.configurationFile.seatAvailable ? this.config.configurationFile.seatAvailable : "";
  seatAvailableOpacity:string = this.config.configurationFile.seatAvailableOpacity ? this.config.configurationFile.seatAvailableOpacity : "";
  seatAvailableHover:string = this.config.configurationFile.seatAvailableHover ? this.config.configurationFile.seatAvailableHover : "";
  seatAvailableHoverOpacity:string = this.config.configurationFile.seatAvailableHoverOpacity ? this.config.configurationFile.seatAvailableHoverOpacity : "";
  seatUnavailable:string = this.config.configurationFile.seatUnavailable ? this.config.configurationFile.seatUnavailable : "";
  seatUnavailableOpacity:string = this.config.configurationFile.seatUnavailableOpacity ? this.config.configurationFile.seatUnavailableOpacity : "";
  seatSelected:string = this.config.configurationFile.seatSelected ? this.config.configurationFile.seatSelected : "";
  seatSelectedOpacity:string = this.config.configurationFile.seatSelectedOpacity ? this.config.configurationFile.seatSelectedOpacity : "";
  seatSelectedHover:string = this.config.configurationFile.seatSelectedHover ? this.config.configurationFile.seatSelectedHover : "";
  seatSelectedHoverOpacity:string = this.config.configurationFile.seatSelectedHoverOpacity ? this.config.configurationFile.seatSelectedHoverOpacity : "";

  seatOpacity:string = this.config.configurationFile.seatOpacity ? this.config.configurationFile.seatOpacity : "";

  // updateTextInput(val, type) {
  //   console.log(val)
  //   if(type === 'block'){
  //     (document.getElementById('blockOpacity') as any).value = val.target.value; 
  //     this.blockOpacity = val.target.value;
  //   } else if(type === 'seat'){
  //     (document.getElementById('seatOpacity') as any).value = val.target.value; 
  //     this.seatOpacity = val.target.value;
  //   }
  // }

  resetValues(){
    this.blockAvailable = "";
    this.blockAvailableOpacity = "";
    this.blockAvailableStroke = "";
    this.blockAvailableStrokeOpacity = "";
  
    this.blockAvailableHover = "";
    this.blockAvailableHoverOpacity = "";
    this.blockAvailableHoverStroke = "";
    this.blockAvailableHoverStrokeOpacity = "";
    
    this.blockSelected = "";
    this.blockSelectedOpacity = "";
    this.blockSelectedStroke =  "";
    this.blockSelectedStrokeOpacity = "";
  
    this.blockSelectedHover = "";
    this.blockSelectedHoverOpacity = "";
    this.blockSelectedHoverStroke = "";
    this.blockSelectedHoverStrokeOpacity = "";
  
    this.blockUnavailable = "";
    this.blockUnavailableOpacity = "";
    this.blockUnavailableStroke = "";
    this.blockUnavailableStrokeOpacity = "";

    this.blockFiltered = "";
    this.blockFilteredOpacity = "";
    this.blockFilteredHover = "";
    this.blockFilteredHoverOpacity = "";
    this.blockFilteredUnavailable = "";
    this.blockFilteredUnavailableOpacity = "";

    this.blockFilteredStroke = "";
    this.blockFilteredStrokeOpacity = "";
    this.blockFilteredHoverStroke = "";
    this.blockFilteredHoverStrokeOpacity = "";
    this.blockFilteredUnavailableStroke = "";
    this.blockFilteredUnavailableStrokeOpacity = "";
  }

  fillAll() {
    this.blockAvailable = "#fff";
    this.blockAvailableOpacity = "0.1";
    this.blockAvailableStroke = "#fff";
    this.blockAvailableStrokeOpacity = "0.6";
  
    this.blockAvailableHover = "#fff";
    this.blockAvailableHoverOpacity = "0.8";
    this.blockAvailableHoverStroke = "#fff";
    this.blockAvailableHoverStrokeOpacity = "1";
    
    this.blockSelected = "#1f8efa";
    this.blockSelectedOpacity = "1";
    this.blockSelectedStroke =  "#fff";
    this.blockSelectedStrokeOpacity = "1";
  
    this.blockSelectedHover = "#cce6ff";
    this.blockSelectedHoverOpacity = "0.8";
    this.blockSelectedHoverStroke = "#1f8efa";
    this.blockSelectedHoverStrokeOpacity = "1";
  
    this.blockUnavailable = "#6a6a6a";
    this.blockUnavailableOpacity = "0.4";
    this.blockUnavailableStroke = "#fff";
    this.blockUnavailableStrokeOpacity = "0.8";

    this.blockFiltered = "#fff";
    this.blockFilteredOpacity = "0.7";
    this.blockFilteredHover = "#fff";
    this.blockFilteredHoverOpacity = "1";
    this.blockFilteredUnavailable = "#000";
    this.blockFilteredUnavailableOpacity = "0.4";

    this.blockFilteredStroke = "#fff";
    this.blockFilteredStrokeOpacity = "0.7";
    this.blockFilteredHoverStroke = "#fff";
    this.blockFilteredHoverStrokeOpacity = "1";
    this.blockFilteredUnavailableStroke = "#000";
    this.blockFilteredUnavailableStrokeOpacity = "0.4";
  }

  resetValuesSeat(){
    this.seatAvailable = "";
    this.seatAvailableOpacity = "";
  
    this.seatAvailableHover = "";
    this.seatAvailableHoverOpacity = "";
    
    this.seatSelected = "";
    this.seatSelectedOpacity = "";
  
    this.seatSelectedHover = "";
    this.seatSelectedHoverOpacity = "";
  
    this.seatUnavailable = "";
    this.seatUnavailableOpacity = "";
  }

  fillAllSeat() {
    this.seatAvailable = "#36b369";
    this.seatAvailableOpacity = "0.7";
  
    this.seatAvailableHover = "#CCFFE1";
    this.seatAvailableHoverOpacity = "0.7";
    
    this.seatSelected = "#FF4D4D";
    this.seatSelectedOpacity = "0.7";
  
    this.seatSelectedHover = "#FFCCCC";
    this.seatSelectedHoverOpacity = "0.7";
  
    this.seatUnavailable = "#fff";
    this.seatUnavailableOpacity = "0";
  }

  validateData() {
    this.config.configurationFile.bgHeader = this.bgHeader;
    this.config.configurationFile.txtHeader = this.txtHeader;
    this.config.configurationFile.bgTopbar = this.bgTopbar;
    this.config.configurationFile.ticketLab = this.ticketLab;
    this.config.configurationFile.ticketVal = this.ticketVal;
    this.config.configurationFile.bgPopover = this.bgPopover;
    this.config.configurationFile.popoverLab = this.popoverLab;
    this.config.configurationFile.popoverVal = this.popoverVal;
    this.config.configurationFile.bgButton = this.bgButton;
    this.config.configurationFile.buttonLab = this.buttonLab;

    this.config.configurationFile.blockAvailable = this.blockAvailable;
    this.config.configurationFile.blockAvailableOpacity = this.blockAvailableOpacity;
    this.config.configurationFile.blockAvailableStroke = this.blockAvailableStroke;
    this.config.configurationFile.blockAvailableStrokeOpacity = this.blockAvailableStrokeOpacity;

    this.config.configurationFile.blockAvailableHover = this.blockAvailableHover;
    this.config.configurationFile.blockAvailableHoverOpacity = this.blockAvailableHoverOpacity;
    this.config.configurationFile.blockAvailableHoverStroke = this.blockAvailableHoverStroke;
    this.config.configurationFile.blockAvailableHoverStrokeOpacity = this.blockAvailableHoverStrokeOpacity;

    this.config.configurationFile.blockUnavailable = this.blockUnavailable;
    this.config.configurationFile.blockUnavailableOpacity = this.blockUnavailableOpacity;
    this.config.configurationFile.blockUnavailableStroke = this.blockUnavailableStroke;
    this.config.configurationFile.blockUnavailableStrokeOpacity = this.blockUnavailableStrokeOpacity;

    this.config.configurationFile.blockSelected = this.blockSelected;
    this.config.configurationFile.blockSelectedOpacity = this.blockSelectedOpacity;
    this.config.configurationFile.blockSelectedStroke = this.blockSelectedStroke;
    this.config.configurationFile.blockSelectedStrokeOpacity = this.blockSelectedStrokeOpacity;

    this.config.configurationFile.blockSelectedHover = this.blockSelectedHover;
    this.config.configurationFile.blockSelectedHoverOpacity = this.blockSelectedHoverOpacity;
    this.config.configurationFile.blockSelectedHoverStroke = this.blockSelectedHoverStroke;
    this.config.configurationFile.blockSelectedHoverStrokeOpacity = this.blockSelectedHoverStrokeOpacity;

    this.config.configurationFile.blockFiltered = this.blockFiltered;
    this.config.configurationFile.blockFilteredOpacity = this.blockFilteredOpacity;
    this.config.configurationFile.blockFilteredHover = this.blockFilteredHover;
    this.config.configurationFile.blockFilteredHoverOpacity = this.blockFilteredHoverOpacity;
    this.config.configurationFile.blockFilteredUnavailable = this.blockFilteredUnavailable;
    this.config.configurationFile.blockFilteredUnavailableOpacity = this.blockFilteredUnavailableOpacity;

    this.config.configurationFile.blockFilteredStroke = this.blockFilteredStroke;
    this.config.configurationFile.blockFilteredStrokeOpacity = this.blockFilteredStrokeOpacity;
    this.config.configurationFile.blockFilteredHoverStroke = this.blockFilteredHoverStroke;
    this.config.configurationFile.blockFilteredHoverStrokeOpacity = this.blockFilteredHoverStrokeOpacity;
    this.config.configurationFile.blockFilteredUnavailableStroke = this.blockFilteredUnavailableStroke;
    this.config.configurationFile.blockFilteredUnavailableStrokeOpacity = this.blockFilteredUnavailableStrokeOpacity;

    this.config.configurationFile.seatAvailable = this.seatAvailable;
    this.config.configurationFile.seatAvailableOpacity = this.seatAvailableOpacity;
    this.config.configurationFile.seatAvailableHover = this.seatAvailableHover;
    this.config.configurationFile.seatAvailableHoverOpacity = this.seatAvailableHoverOpacity;

    this.config.configurationFile.seatUnavailable = this.seatUnavailable;
    this.config.configurationFile.seatUnavailableOpacity = this.seatUnavailableOpacity;

    this.config.configurationFile.seatSelected = this.seatSelected;
    this.config.configurationFile.seatSelectedOpacity = this.seatSelectedOpacity;
    this.config.configurationFile.seatSelectedHover = this.seatSelectedHover;
    this.config.configurationFile.seatSelectedHoverOpacity = this.seatSelectedHoverOpacity;

    this.config.breadcrumbs.push({name:"Config", state:"common.config"});
    this.state.go('common.config');
  }

  constructor(private config: ConfigurationService, private state: StateService) { }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    }
  }
}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { TK3D_CONFIG, Tk3dConfig, Tk3dService } from 'ngx-tk3d';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions'

declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  //config
  minisiteUrl: string = this.config.configurationFile.minisiteUrl;
  venueID: string = this.config.configurationFile.venueID;
  mapID: string = this.config.configurationFile.mapID;
  minimapID: string = this.config.configurationFile.minimapID;
  ticketsUrl: string = this.config.configurationFile.ticketsUrl;
  ticketsName: string = this.config.configurationFile.ticketsName;
  venueName: string = this.config.configurationFile.venueName;
  viewType: string = this.config.configurationFile.viewType;
  exceptions: string[] = this.config.configurationFile.exceptions;
  buyTickets:boolean = this.config.configurationFile.buyTickets;
  popupCheck: string = this.config.configurationFile.popupImg;
  hasMinimap: boolean = this.config.configurationFile.hasMinimap;
  subtitleName: string = this.config.configurationFile.subtitleName ? this.config.configurationFile.subtitleName : "3D seatmap";
  popupJsonCheck: boolean = this.config.configurationFile.popupJsonCheck;
  exceptionsJsonCheck: boolean = this.config.configurationFile.exceptionsJsonCheck;
  popupjson: any = { clientDoc: {} };
  exceptionsjson: any = { clientDoc: {} };
  isPopupJson = false;
  isExceptionsJson = false;
  localJsonPopup = this.config.configurationFile.popupJson;
  localJsonExceptions = this.config.configurationFile.exceptionsJson;
  minimapZoom = this.config.configurationFile.minimapZoom;
  hasPano: boolean = this.config.configurationFile.hasPano;


  validateForm(form: FormData) {

  }

  checked() {
    if($("#buyTickets").prop('checked')){
      this.buyTickets = true
    } else {
      this.buyTickets = false
    }
  }

  checkedMinimap() {
    if($("#hasMinimap").prop('checked')){
      this.hasMinimap = true
    } else {
      this.hasMinimap = false
    }
  }

  uploadFile(event, file, key) {
    if(key === "popupjson") {
      this.popupjson.clientMandateForm = event.srcElement.files[0];
      this.config.popupJson = this.popupjson.clientMandateForm; 
    } else if (key === "exceptionsjson") {
      this.exceptionsjson.clientMandateForm = event.srcElement.files[0];
      this.config.exceptionsJson = this.exceptionsjson.clientMandateForm; 
    }

    if (file.length === 0)
      return;

    var reader = new FileReader();

    reader.readAsText(file[0]); 
    reader.onload = () => {
      if(key === "popupjson") {
        this.config.popupJsonPre = reader.result; 
        this.config.popupJsonPre = JSON.parse(this.config.popupJsonPre);
        this.isPopupJson = true;
        $("#popupJsonName").removeClass("hidden");
        $("#popupJsonName").text(file[0].name);
        this.config.configurationFile.popupJson = this.config.popupJsonPre;
      } else if(key === "exceptionsjson") {
        this.config.exceptionsJsonPre = reader.result; 
        this.config.exceptionsJsonPre = JSON.parse(this.config.exceptionsJsonPre);
        this.isExceptionsJson = true;
        $("#exceptionsJsonName").removeClass("hidden");
        $("#exceptionsJsonName").text(file[0].name);
        this.config.configurationFile.exceptionsJson = this.config.exceptionsJsonPre;
      }
    }
  }

  @ViewChild('popupjson') inputPopup: ElementRef;
  @ViewChild('exceptionsjson') inputExceptions: ElementRef;

  removeFile(key) {
    if(key === "popupjson") {
      this.inputPopup.nativeElement.value = null;
      this.isPopupJson = false;
      $("#popupJsonName").addClass("hidden");
      $("#popupJsonName").text("");
      this.config.configurationFile.popupJson = null;
    } else if(key === "exceptionsjson") {
      this.inputExceptions.nativeElement.value = null;
      this.isExceptionsJson = false;
      $("#exceptionsJsonName").addClass("hidden");
      $("#exceptionsJsonName").text("");
      this.config.configurationFile.exceptionsJson = null;
    }
  }

  checkedPopupJson() {
    if($("#popupJsonCheck").prop('checked')) {
        this.popupJsonCheck = true;
        document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    } else {
        this.popupJsonCheck = false;
        document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
    }
  }

  checkedExceptionsJson() {
    if($("#exceptionsJsonCheck").prop('checked')) {
      this.exceptionsJsonCheck = true;
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    } else {
        this.exceptionsJsonCheck = false;
        document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
    }
  }

  onDownloadPopupJson() {
    this.download(JSON.stringify(this.config.configurationFile.popupJson), "popup.json", "application/json");
  }

  onDownloadExceptionsJson() {
    this.download(JSON.stringify(this.config.configurationFile.exceptionsJson), "exceptions.json", "application/json");
  }

  download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  validateData() {
    if(this.exceptions) {
        var toArray = this.exceptions.toString().replace(/\s/g, ",").split(",");
        var removeSpaces = toArray.filter(Boolean)
        this.exceptions = removeSpaces;
    } else {
        this.exceptions = [""];
    }
    
    if($("#section").prop('checked')) {
        this.viewType = "section"
    } else {
        this.viewType = "seat"
    }

    this.config.configurationFile.minisiteUrl = this.minisiteUrl;
    this.config.configurationFile.venueID = this.venueID;
    this.config.configurationFile.minimapID = this.minimapID;
    this.config.configurationFile.mapID = this.mapID;

    if(this.buyTickets) {
      if(this.ticketsUrl.includes("https://")) {
        this.ticketsUrl = this.ticketsUrl;
      } else {
        this.ticketsUrl = "https://"+this.ticketsUrl;
      }

      this.config.configurationFile.ticketsUrl = this.ticketsUrl;
      this.config.configurationFile.ticketsName = this.ticketsName;
      this.config.configurationFile.buyTickets = true;
    } else {
      this.config.configurationFile.buyTickets = false;
    }

    if($("#img").prop('checked')){
      this.popupCheck = "img";
    } else {
      this.popupCheck = "noimg";
    }

    if($("#popupJsonCheck").prop('checked')){
      this.popupJsonCheck = true;
    } else {
      this.popupJsonCheck = false;
    }

    if($("#exceptionsJsonCheck").prop('checked')){
      this.exceptionsJsonCheck = true;
    } else {
      this.exceptionsJsonCheck = false;
    }

    if($("#pano").prop('checked')) {
      this.hasPano = true
    } else {
      this.hasPano = false
    }

    this.config.configurationFile.popupImg = this.popupCheck;

    this.config.configurationFile.venueName = this.venueName;
    this.config.configurationFile.subtitleName = this.subtitleName;
    this.config.configurationFile.viewType = this.viewType;
    this.config.configurationFile.hasPano = this.hasPano;
    this.config.configurationFile.exceptions = this.exceptions;
    this.config.configurationFile.hasMinimap = this.hasMinimap;
    this.config.configurationFile.popupJsonCheck = this.popupJsonCheck;
    this.config.configurationFile.exceptionsJsonCheck = this.exceptionsJsonCheck;
    this.config.configurationFile.minimapZoom = this.minimapZoom ? this.minimapZoom : 1;

    this.config.configurationFile.flag = "TK3D";

    this.tk3d_config.mapId = this.config.configurationFile['mapID'];
    this.tk3d_config.venue = this.config.configurationFile['venueID'];
    this.tk3d.changeVenue(this.config.configurationFile['venueID']);

    console.log(this.config.configurationFile)

    this.config.breadcrumbs.push({name:"Custom", state:"common.custom"});
    this.state.go('common.custom');
    
  }

  constructor(private config: ConfigurationService, private state: StateService,
    @Inject(TK3D_CONFIG) private tk3d_config: Tk3dConfig, private tk3d: Tk3dService) {
  }

  ngOnInit() {
    console.log(this.config.configurationFile)
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    } else {
      if(this.viewType === 'seat') {
        $("#seat").prop('checked', true);
        $("#section").prop('checked', false);
      } else if(this.viewType === 'section') {
        $("#seat").prop('checked', false);
        $("#section").prop('checked', true);
      }

      if(this.popupCheck === 'img') {
        $("#img").prop('checked', true);
        $("#noImg").prop('checked', false);
      } else if(this.popupCheck === 'noimg') {
        $("#img").prop('checked', false);
        $("#noImg").prop('checked', true);
      }

      if(this.hasPano === true) {
        $("#pano").prop('checked', true);
        $("#noPano").prop('checked', false);
      } else if(this.hasPano === false) {
        $("#pano").prop('checked', false);
        $("#noPano").prop('checked', true);
      }

      if(this.popupJsonCheck) {
        document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
      }

      if(this.exceptionsJsonCheck) {
        document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
      }
    }
  }
}

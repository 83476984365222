import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ConfigurationService } from '../../services/configuration.service';
import { StateService } from '@uirouter/core';
import * as global from '../../services/global-functions';

declare var $: any;


@Component({
  selector: 'app-config-files',
  templateUrl: './config-files.component.html',
  styleUrls: ['./config-files.component.scss']
})
export class ConfigFilesComponent implements OnInit {
    constructor(private config: ConfigurationService, private modalService: BsModalService,
        private state: StateService) { }

    modalRef: BsModalRef;
    modalConfig = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: ' modal-dialog-centered'
    };
    
    //
    filtersCheck: boolean = this.config.configurationFile.filters;
    navigationCheck: boolean = this.config.configurationFile.navigation;
    searchbarCheck: boolean = this.config.configurationFile.searchbar;
    unavailableCheck: boolean = this.config.configurationFile.unavailable;
    tabsCheck: boolean = this.config.configurationFile.tabs;
    tabsCheckManual: boolean = this.config.configurationFile.tabsManual;
    neighboursCheck: boolean = this.config.configurationFile.neighbours;
    leftRightCheck: boolean = this.config.configurationFile.leftRight ;
    translationCheck: boolean = this.config.configurationFile.translation;
    logoCheck:boolean = this.config.configurationFile.logo;
    bgCheck:boolean = this.config.configurationFile.bg;
    faviconCheck:boolean = this.config.configurationFile.favicon;
    infoMenuCheck: boolean = this.config.configurationFile.infoMenuCheck;
    infoMenuTitle: string = this.config.configurationFile.infoMenuTitle;
    logoUrl: string = this.config.configurationFile.logoUrl;
    logoType:string = this.config.configurationFile.logoType;
    faviconType:string = this.config.configurationFile.faviconType;
    copyLinkCheck:boolean = this.config.configurationFile.copyLinkCheck;

    localJsonTabs = this.config.configurationFile.tabOptionsJson;
    localJsonNavigation = this.config.configurationFile.navigationJson;
    localJsonNeighbours = this.config.configurationFile.neighboursOptionsJson;
    localJsonFiltering = this.config.configurationFile.filteringJson;
    localJsonUnavailable = this.config.configurationFile.unavailableOptionsJson;

    // filters array & values
    filters = [];

    // infomenu array & values
    options = this.config.configurationFile.infoMenuOptions;
    campo: string;
    valor: string;
    index: number;

    // create and delete menu
    createOption() {
        var o = {
            campo: this.campo,
            valor: this.valor
        };

        this.options.push(o);
        this.campo = "";
        this.valor = "";
        this.modalRef.hide();
    }

    createOptionEd() {
        this.options[$("#index").val()].campo = $("#campo").val();
        this.options[$("#index").val()].valor = $("#valor").val();
        this.campo = "";    
        this.valor = "";    
        this.modalRef.hide();
    }

    editOption(template: TemplateRef<any>, opt, index) {
        var initialState = {index: index,campo: opt['campo'], valor: opt['valor']};
        this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});
        this.campo = opt['campo'];
        this.valor = opt['valor'];
        this.index = index;
        this.modalRef.content = initialState;
    }

    deleteOption(o) {
        this.options.splice(o, 1);
    }

    get menuoptions() {
        return this.options;
    }

    optTabs = [];
    imgActive;
    imgLogoactive;
    imgBgactive;
    name;
    venue;
    map;
    minimap;
    active;
    topbarColor;
    textColor;
    idColor;
    order;
    
    createTab() {
        this.order = $("#order").val();
        this.name = $("#name").val();
        this.venue = $("#venue").val();
        this.map = $("#map").val();
        this.minimap = $("#minimap").val();
        this.active = $("#active").val();
        this.topbarColor = $("#topbarColor").val();
        this.textColor = $("#textColor").val();
        this.idColor = $("#idColor").val();

        var json = {
            "order":this.order,
            "name": this.name,
            "venueid":this.venue,
            "mapid":this.map,
            "minimapid":this.minimap,
            "active":this.active,
            "topbarColor":this.topbarColor,
            "textColor":this.textColor,
            "idColor":this.idColor,
            "imgActive":this.imgActive,
            "imgLogoactive":this.imgLogoactive,
            "imgBgactive":this.imgBgactive
        }
        
        this.optTabs.push(json)
        this.config.configurationFile.tabOptionsJson = this.addToObject(this.config.configurationFile.tabOptionsJson, this.order, json, 0);

        this.order = "";
        this.name = "";
        this.venue = "";
        this.map = "";
        this.minimap = "";
        this.active = "";
        this.topbarColor = "";
        this.textColor = "";
        this.idColor = "";
        this.campo = "";
        this.valor = "";
        this.modalRef.hide();
    }

    createTabEd() {
        this.optTabs[$("#index").val()].order = $("#order").val();
        this.optTabs[$("#index").val()].name = $("#name").val();
        this.optTabs[$("#index").val()].venueid = $("#venue").val();
        this.optTabs[$("#index").val()].mapid = $("#map").val();
        this.optTabs[$("#index").val()].minimapid = $("#minimap").val();
        this.optTabs[$("#index").val()].active = $("#active").val();
        this.optTabs[$("#index").val()].topbarColor = $("#topbarColor").val();
        this.optTabs[$("#index").val()].textColor = $("#textColor").val();
        this.optTabs[$("#index").val()].idColor = $("#idColor").val();
        this.optTabs[$("#index").val()].imgActive = this.imgActive;
        this.optTabs[$("#index").val()].imgLogoactive = this.imgLogoactive;
        this.optTabs[$("#index").val()].imgBgactive = this.imgBgactive;

        this.order = "";
        this.name = "";
        this.venue = "";
        this.map = "";
        this.minimap = "";
        this.active = "";
        this.topbarColor = "";
        this.textColor = "";
        this.idColor = "";
        this.campo = "";
        this.valor = "";

        this.modalRef.hide();
        
    }

    editTab(template: TemplateRef<any>, opt, index) {
        var initialState = {index:index, order: opt['order'], name: opt['name'], venue: opt['venueid'], map: opt['mapid'], minimap: opt['minimapid'],
                            active: opt['active'],topbarColor: opt['topbarColor'],textColor: opt['textColor'],
                            idColor: opt['idColor']};

        this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});

        this.index = index;
        this.order = opt['order'];
        this.name = opt['name'];
        this.venue = opt['venueid'];
        this.map = opt['mapid'];
        this.minimap = opt['minimapid'];
        this.active = opt['active'];
        this.topbarColor = opt['topbarColor'];
        this.textColor = opt['textColor'];
        this.idColor = opt['idColor'];
        this.campo = opt['campo'];
        this.valor = opt['valor'];

        this.imgActive = opt['imgActive'];
        this.imgLogoactive = opt['imgLogoactive'];
        this.imgBgactive = opt['imgBgactive']

        this.modalRef.content = initialState;
    }

    deleteTab(tab, o) {
        this.optTabs.splice(o, 1);
        delete this.config.configurationFile.tabOptionsJson[tab.name];
    }

    addToObject(obj, key, value, index) {
        // Create a temp object and index variable
        var temp = {};
        var i = 0;
    
        // Loop through the original object
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
    
                // If the indexes match, add the new item
                if (i === index && key && value) {
                    temp[key] = value;
                }
    
                // Add the current item in the loop to the temp obj
                temp[prop] = obj[prop];
    
                // Increase the count
                i++;
    
            }
        }
    
        // If no index, add to the end
        if (!index && key && value) {
            temp[key] = value;
        }
    
        return temp;
    };

    get tabsOptions(){
        return this.optTabs;
    }

    checkedInfoMenu() {
        if($("#infoMenuCheck").prop('checked')) {
            this.infoMenuCheck = true;
            document.getElementById("infoMenuCheck").classList.remove("disabled");
        } else {
            this.infoMenuCheck = false;
            document.getElementById("infoMenuCheck").classList.add("disabled");
        }
    }

    download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    onDownloadFilteringJson(){
        this.download(JSON.stringify(this.config.configurationFile.filteringJson), "filtering.json", "application/json");
    }

    onDeleteFilteringJson() {
        this.config.configurationFile.filteringJson = {};
    }

    onDownloadNavigationJson(){
        this.download(JSON.stringify(this.config.configurationFile.navigationJson), "navigations.json", "application/json");
    }

    onDeleteNavigationJson(){
        this.config.configurationFile.navigationJson = null;
    }

    onDownloadTabsJson(){
        this.download(JSON.stringify(this.config.configurationFile.tabOptionsJson), "tabs.json", "application/json");
    }

    onDeleteTabsJson(){
        this.config.configurationFile.tabOptionsJson = {};
    }

    onDownloadNeighboursJson(){
        this.download(JSON.stringify(this.config.configurationFile.neighboursOptionsJson), "neighbours.json", "application/json");
    }

    onDeleteNeighboursJson(){
        this.config.configurationFile.neighboursOptionsJson = {};
    }

    onDownloadUnavailableJson(){
        this.download(JSON.stringify(this.config.configurationFile.unavailableOptionsJson), "unavailable.json", "application/json");
    }

    onDeleteUnavailableJson(){
        this.config.configurationFile.unavailableOptionsJson = {};
    }

    onDownloadTranslationJson(){
        this.download(JSON.stringify(this.config.configurationFile.translationOptionsJson), "translation.json", "application/json");
    }

    onDeleteTranslationJson(){
        this.config.configurationFile.translationOptionsJson = {};
    }

    logoUploaded = false;
    bgUploaded = false;
    favUploaded = false;
    logoUpload: any = { clientDoc: {} };
    bgUpload: any = { clientDoc: {} };
    favUpload: any = { clientDoc: {} };
    logoPreview: any;
    bgPreview: any;
    favPreview:any;

    @ViewChild('logo') inputLogo: ElementRef;
    @ViewChild('bg') inputBg: ElementRef;
    @ViewChild('fav') inputFav: ElementRef;

    checkedLogo() {
        if($("#logoCheck").prop('checked')) {
            this.logoCheck = true;
        } else {
            this.logoCheck = false;
        }
    }

    uploadLogo(event, file) {    
        //for upload
        this.logoUpload.clientMandateForm = event.srcElement.files[0];
        this.config.logo = this.logoUpload.clientMandateForm; 

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
            this.config.logoPreview = reader.result; 
            $("#logo-container").attr("src", this.config.logoPreview);
        }

        this.logoUploaded = true;
        $("#logoName").removeClass("hidden");
        $("#logoName").text(file[0].name);
    }

    removeLogo() {
        this.inputLogo.nativeElement.value = null;
        this.logoUploaded = false;
        $("#logoName").addClass("hidden");
        $("#logoName").text("");
        $("#logo-container").attr("src", "");
    }

    checkedFavicon() {
        if($("#faviconCheck").prop('checked')) {
            this.faviconCheck = true;
        } else {
            this.faviconCheck = false;
        }
    }

    uploadFav(event, file) {
        //for upload
        this.favUpload.clientMandateForm = event.srcElement.files[0];
        this.config.favicon = this.favUpload.clientMandateForm;

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
        this.config.favPreview = reader.result; 
        $("#fav-container").attr("src", this.config.favPreview);
        }

        this.favUploaded = true;
        $("#favName").removeClass("hidden");
        $("#favName").text(file[0].name);
    }

    removeFav() {
        this.inputFav.nativeElement.value = null;
        this.favUploaded = false;
        $("#favName").addClass("hidden");
        $("#favName").text("");
        $("#fav-container").attr("src", "");
    }

    checkedBg() {
        if($("#bgCheck").prop('checked')) {
            this.bgCheck = true;
        } else {
            this.bgCheck = false;
        }
    }

    uploadBg(event, file) {
        //for upload
        this.bgUpload.clientMandateForm = event.srcElement.files[0];
        this.config.background = this.bgUpload.clientMandateForm; 

        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
        this.config.bgPreview = reader.result; 
        $("#bg-container").attr("src", this.config.bgPreview);
        }

        this.bgUploaded = true;
        $("#bgName").removeClass("hidden");
        $("#bgName").text(file[0].name);
    }

    removeBg() {
        this.inputBg.nativeElement.value = null;
        this.bgUploaded = false;
        $("#bgName").addClass("hidden");
        $("#bgName").text("");
        $("#bg-container").attr("src", "");

    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.modalConfig);
    }

    uploadTabActiveImg(event, file){
        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 

        $("#activeImgName").removeClass("hidden");
        $("#activeImgName").text(file[0].name);

        reader.onload = (_event) => { 
            this.imgActive = reader.result;
        }

    }

    removeActiveImg() {
        this.imgActive = false;
        $("#activeImgName").text("");
    }

    uploadTabLogoactiveImg(event, file){
        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
            this.imgLogoactive = reader.result;
        }

        $("#logoactiveImgName").removeClass("hidden");
        $("#logoactiveImgName").text(file[0].name);
    }

    removeLogoactiveImg() {
        this.imgLogoactive = false;
        $("#logoactiveImgName").text("");
    }

    uploadTabBgactiveImg(event, file){
        //for preview
        if (file.length === 0)
        return;
    
        var reader = new FileReader();
        reader.readAsDataURL(file[0]); 
        reader.onload = (_event) => { 
            this.imgBgactive = reader.result;
        }

        $("#bgactiveImgName").removeClass("hidden");
        $("#bgactiveImgName").text(file[0].name);
    }

    removeBgactiveImg() {
        this.imgBgactive = false;
        $("#bgactiveImgName").text("");
    }

    // upload files
    // read files for upload
    filtJson: any = { clientDoc: {} };
    navJson: any = { clientDoc: {} };
    tabsOptJson: any = { clientDoc: {} };
    neighboursOptJson: any = { clientDoc: {} };
    unavailableOptJson: any = { clientDoc: {} };
    translationOptJson: any = { clientDoc: {} };

    //ngModal // read files for use in preview and modal
    isFilteringJson = false;
    isNavigationJson = false;
    isTabsJson = false;
    isNeighboursJson = false;
    isUnavailableJson = false;
    isTranslationJson = false;

    checkedSearchbar() {
        if($("#searchbarCheck").prop('checked')) {
            this.searchbarCheck = true;
            document.getElementById("searchbarCheck").classList.remove("disabled");
        } else {
            this.searchbarCheck = false;
            document.getElementById("searchbarCheck").classList.add("disabled");
        }
    } 

    checkedFilters() {
        if($("#filtersCheck").prop('checked')) {
          this.filtersCheck = true;
          document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
        } else {
          this.filtersCheck = false;
          document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
        }
    }
    
    checkedNavigation() {
        if($("#navigationCheck").prop('checked')) {
            this.navigationCheck = true;
            document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
        } else {
            this.navigationCheck = false;
            document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
        }
    }

    checkedTabs() {
        if($("#tabsCheck").prop('checked')) {
            this.tabsCheck = true;
            document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
        } else {
            this.tabsCheck = false;
            document.getElementsByClassName("uploadBtn")[2].classList.add("disabled");
        }
    }

    checkedTabsManual() {
        if($("#tabsCheckManual").prop('checked')) {
            this.tabsCheckManual = true;
        } else {
            this.tabsCheckManual = false;
        }
    }

    checkedNeighbours(){
        if($("#neighboursCheck").prop('checked')) {
            this.neighboursCheck = true;
            document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
        } else {
            this.neighboursCheck = false;
            document.getElementsByClassName("uploadBtn")[3].classList.add("disabled");
        }
    }

    checkedLeftRight() {
        if($("#leftRightCheck").prop('checked')) {
          this.leftRightCheck = true;
        } else {
          this.leftRightCheck = false;
        }
      }

    checkedUnavailable() {
        if($("#unavailableCheck").prop('checked')) {
            this.unavailableCheck = true;
            document.getElementsByClassName("uploadBtn")[4].classList.remove("disabled");
        } else {
            this.unavailableCheck = false;
            document.getElementsByClassName("uploadBtn")[4].classList.add("disabled");
        }
    }

    checkedTranslation() {
        if($("#translationCheck").prop('checked')) {
            this.translationCheck = true;
            document.getElementsByClassName("uploadBtn")[5].classList.remove("disabled");
        } else {
            this.translationCheck = false;
            document.getElementsByClassName("uploadBtn")[5].classList.add("disabled");
        }
    } 

    checkedCopyLink() {
        if($("#copyLinkCheck").prop('checked')) {
          this.copyLinkCheck = true;
          document.getElementById("copyLinkCheck").classList.remove("disabled");
        } else {
          this.copyLinkCheck = false;
          document.getElementById("copyLinkCheck").classList.add("disabled");
        }
      }

    // remove files uploaded
    @ViewChild('filterJson') inputFilt: ElementRef;
    @ViewChild('navigationJson') inputNavigation: ElementRef;
    @ViewChild('blocks') inputUnavailable: ElementRef;
    @ViewChild('tabs') inputTabs: ElementRef;
    @ViewChild('neighbours') inputNeighbours: ElementRef;
    @ViewChild('translation') inputTranslation: ElementRef;

    uploadFile(event, file, key) {
        if(key === "filtering") {
            this.filtJson.clientMandateForm = event.srcElement.files[0];
            this.config.filteringJson = this.filtJson.clientMandateForm; 
        } else if(key === "navigation"){
            this.navJson.clientMandateForm = event.srcElement.files[0];
            this.config.navigationJson = this.navJson.clientMandateForm; 
        }else if(key === "tabs") {
            this.tabsOptJson.clientMandateForm = event.srcElement.files[0];
            this.config.tabOptionsJson = this.tabsOptJson.clientMandateForm;
        } else if(key === "neighbours") {
            this.neighboursOptJson.clientMandateForm = event.srcElement.files[0];
            this.config.neighboursOptionsJson = this.neighboursOptJson.clientMandateForm;
        } else if (key === "unavailable") {
            this.unavailableOptJson.clientMandateForm = event.srcElement.files[0];
            this.config.unavailableOptionsJson = this.unavailableOptJson.clientMandateForm;
        }else if (key === "translation") {
            this.translationOptJson.clientMandateForm = event.srcElement.files[0];
            this.config.translationOptionsJson = this.translationOptJson.clientMandateForm;
          }

        if (file.length === 0)
        return;

        var reader = new FileReader();

        reader.readAsText(file[0]); 
        reader.onload = () => { 
            if(key === "filtering") {
                this.config.filteringJsonPre = reader.result; 
                this.config.filteringJsonPre = JSON.parse(this.config.filteringJsonPre);
                this.isFilteringJson = true;
                $("#filteringJson").removeClass("hidden");
                $("#filteringJson").text(file[0].name);
                this.config.configurationFile.filteringJson = this.config.filteringJsonPre;
            } else if(key === "navigation"){
                this.config.navigationJsonPre = reader.result;
                this.config.navigationJsonPre = JSON.parse(this.config.navigationJsonPre);
                this.isNavigationJson = true;
                $("#navigationJson").removeClass("hidden");
                $("#navigationJson").text(file[0].name);
                this.config.configurationFile.navigationJson = this.config.navigationJsonPre;
            } else if(key === "tabs") {
                this.config.tabOptionsJsonPre = reader.result;
                this.config.tabOptionsJsonPre = JSON.parse(this.config.tabOptionsJsonPre);
                this.isTabsJson = true;
                $("#tabsJson").removeClass("hidden");
                $("#tabsJson").text(file[0].name);
                this.config.configurationFile.tabOptionsJson = this.config.tabOptionsJsonPre;
            } else if(key === "neighbours") {
                this.config.neighboursOptionsJsonPre = reader.result;
                this.config.neighboursOptionsJsonPre = JSON.parse(this.config.neighboursOptionsJsonPre);
                this.isNeighboursJson = true;
                $("#neighboursJson").removeClass("hidden");
                $("#neighboursJson").text(file[0].name);
                this.config.configurationFile.neighboursOptionsJson = this.config.neighboursOptionsJsonPre;
            } else if(key === "unavailable") {
                this.config.unavailableOptionsJsonPre = reader.result;
                this.config.unavailableOptionsJsonPre = JSON.parse(this.config.unavailableOptionsJsonPre);
                this.isUnavailableJson = true;
                $("#unavailableJson").removeClass("hidden");
                $("#unavailableJson").text(file[0].name);
                this.config.configurationFile.unavailableOptionsJson = this.config.unavailableOptionsJsonPre;
            }else if(key === "translation") {
                this.config.translationOptionsJsonPre = reader.result;
                this.config.translationOptionsJsonPre = JSON.parse(this.config.translationOptionsJsonPre);
                this.isTranslationJson = true;
                $("#translationJson").removeClass("hidden");
                $("#translationJson").text(file[0].name);
                this.config.configurationFile.translationOptionsJson = this.config.translationOptionsJsonPre;
              }
        }
    }

    removeFile(key) {
        if(key === "filtering") {
            this.inputFilt.nativeElement.value = null;
            this.isFilteringJson = false;
            $("#filteringJson").addClass("hidden");
            $("#filteringJson").text("");

        } else if(key === "navigation") {
            this.inputNavigation.nativeElement.value = null;
            this.isNavigationJson = false;
            $("#navigationJson").addClass("hidden");
            $("#navigationJson").text("");

        } else if(key === "tabs") {
            this.inputTabs.nativeElement.value = null;
            this.isTabsJson = false;
            $("#tabsJson").addClass("hidden");
            $("#tabsJson").text("");

        } else if(key === "neighbours") {
            this.inputNeighbours.nativeElement.value = null;
            this.isNeighboursJson = false;
            $("#neighboursJson").addClass("hidden");
            $("#neighboursJson").text("");

        } else if(key === "unavailable") {
            this.inputUnavailable.nativeElement.value = null;
            this.isUnavailableJson = false;
            $("#unavailableJson").addClass("hidden");
            $("#unavailableJson").text("");
        }else if(key === "translation") {
            this.inputTranslation.nativeElement.value = null;
            this.isTranslationJson = false;
            $("#translationJson").addClass("hidden");
            $("#translationJson").text("");
        }
    }

    //go to preview
    validateData() {
        if($("#filtersCheck").prop('checked')){
            this.filtersCheck = true;
        } else {
            this.filtersCheck = false;
        }
    
        if($("#navigationCheck").prop('checked')){
            this.navigationCheck = true;
        } else {
            this.navigationCheck = false;
        }
    
        if($("#tabsCheck").prop('checked')){
            this.tabsCheck = true;
        } else {
            this.tabsCheck = false;
        }

        if($("#tabsCheckManual").prop('checked')){
            this.tabsCheckManual = true;
        } else {
            this.tabsCheckManual = false;
        }
    
        if($("#searchbarCheck").prop('checked')){
            this.searchbarCheck = true;
        } else {
            this.searchbarCheck = false;
        }

        if($("#neighboursCheck").prop('checked')){
            this.neighboursCheck = true;
        } else {
            this.neighboursCheck = false;
        }

        if($("#leftRightCheck").prop('checked')){
            this.leftRightCheck = true;
        } else {
            this.leftRightCheck = false;
        }

        if($("#infoMenuCheck").prop('checked')){
            this.infoMenuCheck = true;
        } else {
            this.infoMenuCheck = false;
        }

        if($("#pngLogoType").prop('checked')){
            this.logoType = "png";
        } else if($("#svgLogoType").prop('checked')) {
            this.logoType = "svg";
        }

        if($("#pngFaviconType").prop('checked')){
            this.faviconType = "png";
        } else if($("#svgFaviconType").prop('checked')) {
            this.faviconType = "svg";
        } else if($("#icoFaviconType").prop('checked')) {
            this.faviconType = "ico";
        } 

        if($("#unavailableCheck").prop('checked')){
            this.unavailableCheck = true;
        } else {
            this.unavailableCheck = false;
        }

        if($("#translationCheck").prop('checked')){
            this.translationCheck = true;
        } else {
            this.translationCheck = false;
        }

        if($("#backgroundColor").val() !== "") {
            this.config.configurationFile.backgroundColor = $("#backgroundColor").val();
        }

        if($("#copyLinkCheck").prop('checked')) {
            this.copyLinkCheck = true;
          } else {
            this.copyLinkCheck = false;
          }

        this.config.configurationFile.filters = this.filtersCheck;
        this.config.configurationFile.navigation = this.navigationCheck;
        this.config.configurationFile.tabs = this.tabsCheck;
        this.config.configurationFile.tabsManual = this.tabsCheckManual;
        this.config.configurationFile.unavailable = this.unavailableCheck;
        this.config.configurationFile.infoMenuCheck = this.infoMenuCheck;
        this.config.configurationFile.infoMenuTitle = this.infoMenuTitle;
        this.config.configurationFile.infoMenuOptions = this.options;
        this.config.configurationFile.logo = this.logoCheck;
        this.config.configurationFile.bg = this.bgCheck;
        this.config.configurationFile.favicon = this.faviconCheck;
        this.config.configurationFile.searchbar = this.searchbarCheck;
        this.config.configurationFile.neighbours = this.neighboursCheck;
        this.config.configurationFile.leftRight = this.leftRightCheck;
        this.config.configurationFile.logoUrl = this.logoUrl;
        this.config.configurationFile.logoType = this.logoType;
        this.config.configurationFile.faviconType = this.faviconType;
        this.config.configurationFile.translation = this.translationCheck;
        this.config.configurationFile.copyLinkCheck = this.copyLinkCheck;

        this.config.breadcrumbs.push({name:"Preview", state:"common.preview"});
        this.state.go('common.preview');
    }

    logoUrlName;
    bgUrlName;
    faviconUrlName;

    ngOnInit() {
        if(!global.getCookie('access_token')) {
            this.state.go('common.login');
        }

        this.filtersCheck = this.config.configurationFile.filters;
        this.navigationCheck = this.config.configurationFile.navigation;
        this.searchbarCheck = this.config.configurationFile.searchbar;
        this.unavailableCheck = this.config.configurationFile.unavailable;
        this.tabsCheck = this.config.configurationFile.tabs;
        this.tabsCheckManual = this.config.configurationFile.tabsManual;
        this.neighboursCheck = this.config.configurationFile.neighbours;
        this.leftRightCheck = this.config.configurationFile.leftRight ;
        this.infoMenuCheck = this.config.configurationFile.infoMenuCheck;
        this.translationCheck = this.config.configurationFile.translation;
        this.copyLinkCheck = this.config.configurationFile.copyLinkCheck;

        for(var i in this.config.configurationFile.tabOptionsJson){
            this.optTabs.push(this.config.configurationFile.tabOptionsJson[i])
        }

        if(this.filtersCheck) {
            document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
        }

        if(this.navigationCheck) {
            document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
        }

        if(this.tabsCheck) {
            document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
        }

        if(this.neighboursCheck) {
            document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
        }

        if(this.unavailableCheck) {
            document.getElementsByClassName("uploadBtn")[4].classList.remove("disabled");
        }

        if(this.translationCheck) {
            document.getElementsByClassName("uploadBtn")[5].classList.remove("disabled");
        }

        var name = this.config.configurationFile.minisiteUrl;

        if(name) {
            this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.png`;
            this.bgUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/bg.jpg`;
            this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.png`;
        }

        if(this.logoType === 'png') {
            $("#pngLogoType").prop('checked', true);
            $("#svgLogoType").prop('checked', false);
            if(name) {
                this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.png`;
            }
        } else if(this.logoType === 'svg') {
            $("#pngLogoType").prop('checked', false);
            $("#svgLogoType").prop('checked', true);
            if(name){
                this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.svg`;
            }
        }

        if(this.faviconType === 'png') {
            $("#pngFaviconType").prop('checked', true);
            $("#svgFaviconType").prop('checked', false);
            $("#icoFaviconType").prop('checked', false);
            if(name){
                this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.png`;
            }
        } else if(this.faviconType === 'svg') {
            $("#pngFaviconType").prop('checked', false);
            $("#svgFaviconType").prop('checked', true);
            $("#icoFaviconType").prop('checked', false);
            if(name){
                this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.svg`;
            }
        } else if(this.faviconType === 'ico') {
            $("#pngFaviconType").prop('checked', false);
            $("#svgFaviconType").prop('checked', false);
            $("#icoFaviconType").prop('checked', true);
            if(name){
                this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.ico`;
            }
        }   

    }
}

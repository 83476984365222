import { Component, OnInit, ViewChild, ElementRef, TemplateRef, Inject } from '@angular/core';
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
import { StateService } from '@uirouter/angular';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { InfoTableComponent } from './info-table/info-table.component';
declare var $:any;

@Component({
  selector: 'app-files-dvm',
  templateUrl: './files-dvm.component.html',
  styleUrls: ['./files-dvm.component.scss']
})
export class FilesDVMComponent implements OnInit {
  filtersCheck: boolean = this.config.configurationFileDVM.filters;
  navigationCheck: boolean = this.config.configurationFileDVM.navigation;
  tabsCheck:boolean = this.config.configurationFileDVM.tabs;
  tabsCheckManual: boolean = this.config.configurationFileDVM.tabsManual;
  neighboursCheck: boolean = this.config.configurationFileDVM.neighbours;
  leftRightCheck: boolean = this.config.configurationFileDVM.leftRight;
  searchbarCheck:boolean = this.config.configurationFileDVM.searchbar;
  infoMenuCheck: boolean = this.config.configurationFileDVM.infoMenuCheck;
  unavailableCheck: boolean = this.config.configurationFileDVM.unavailable;
  translationCheck: boolean = this.config.configurationFileDVM.translation;
  infoMenuTitle: string = this.config.configurationFileDVM.infoMenuTitle;
  logoCheck:boolean = this.config.configurationFileDVM.logo;
  bgCheck:boolean = this.config.configurationFileDVM.bg;
  faviconCheck:boolean = this.config.configurationFileDVM.favicon;
  navigationPluginCheck:boolean = this.config.configurationFileDVM.navigationPluginCheck;
  indoorPluginCheck: boolean = this.config.configurationFileDVM.indoorPluginCheck;
  multiIndoorCheck: boolean = this.config.configurationFileDVM.multiIndoorCheck;
  galleryPluginCheck: boolean = this.config.configurationFileDVM.galleryPluginCheck;
  bestNodesCheck: boolean = this.config.configurationFileDVM.bestNodesCheck;
  logoUrl: string = this.config.configurationFileDVM.logoUrl;
  logoType = this.config.configurationFileDVM.logoType;
  faviconType = this.config.configurationFileDVM.faviconType;
  copyLinkCheck:boolean = this.config.configurationFileDVM.copyLinkCheck;
  localJsonTabs = this.config.configurationFileDVM.tabOptionsJson;
  localJsonNavigation = this.config.configurationFileDVM.navigationJson;
  localJsonNeighbours = this.config.configurationFileDVM.neighboursOptionsJson;
  localJsonFiltering = this.config.configurationFileDVM.filteringJson;
  localJsonInfomenu = this.config.configurationFileDVM.infoMenuOptions;
  localJsonUnavailable = this.config.configurationFileDVM.unavailableOptionsJson;
  localJsonTranslation = this.config.configurationFileDVM.translationOptionsJson;

  backgroundColor:string = this.config.configurationFileDVM.backgroundColor ? this.config.configurationFileDVM.backgroundColor : "";

  logo: any;
  background: any;

  logoUploaded = false;
  bgUploaded = false;
  favUploaded = false;
  logoUpload: any = { clientDoc: {} };
  bgUpload: any = { clientDoc: {} };
  favUpload: any = { clientDoc: {} };
  logoPreview: any;
  bgPreview: any;
  favPreview:any;

  modalRef: BsModalRef;
  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: ' modal-dialog-centered'
  };

  optTabs = [];
  imgActive;
  imgLogoactive;
  imgBgactive;
  name;
  venue;
  map;
  minimap;
  active;
  topbarColor;
  textColor;
  idColor;
  order;
  
  createTab() {
      this.order = $("#order").val();
      this.name = $("#name").val();
      this.venue = $("#venue").val();
      this.map = $("#map").val();
      this.minimap = $("#minimap").val();
      this.active = $("#active").val();
      this.topbarColor = $("#topbarColor").val();
      this.textColor = $("#textColor").val();
      this.idColor = $("#idColor").val();

      var json = {
              "order":this.order,
              "name": this.name,
              "venueid":this.venue,
              "mapid":this.map,
              "minimapid":this.minimap,
              "active":this.active,
              "topbarColor":this.topbarColor,
              "textColor":this.textColor,
              "idColor":this.idColor,
              "imgActive":this.imgActive,
              "imgLogoactive":this.imgLogoactive,
              "imgBgactive":this.imgBgactive
      }
      
      this.optTabs.push(json)
      this.config.configurationFileDVM.tabOptionsJson = this.addToObject(this.config.configurationFileDVM.tabOptionsJson, this.order, json, 0);

      this.order = "";
      this.name = "";
      this.venue = "";
      this.map = "";
      this.minimap = "";
      this.active = "";
      this.topbarColor = "";
      this.textColor = "";
      this.idColor = "";
      this.campo = "";
      this.valor = "";
      this.modalRef.hide();

  }

  createTabEd() {
      this.optTabs[$("#index").val()].order = $("#order").val();
      this.optTabs[$("#index").val()].name = $("#name").val();
      this.optTabs[$("#index").val()].venueid = $("#venue").val();
      this.optTabs[$("#index").val()].mapid = $("#map").val();
      this.optTabs[$("#index").val()].minimapid = $("#minimap").val();
      this.optTabs[$("#index").val()].active = $("#active").val();
      this.optTabs[$("#index").val()].topbarColor = $("#topbarColor").val();
      this.optTabs[$("#index").val()].textColor = $("#textColor").val();
      this.optTabs[$("#index").val()].idColor = $("#idColor").val();
      this.optTabs[$("#index").val()].imgActive = this.imgActive;
      this.optTabs[$("#index").val()].imgLogoactive = this.imgLogoactive;
      this.optTabs[$("#index").val()].imgBgactive = this.imgBgactive;

      this.order = "";
      this.name = "";
      this.venue = "";
      this.map = "";
      this.minimap = "";
      this.active = "";
      this.topbarColor = "";
      this.textColor = "";
      this.idColor = "";
      this.campo = "";
      this.valor = "";

      this.modalRef.hide();
      
  }

  editTab(template: TemplateRef<any>, opt, index) {
      var initialState = {index:index, order: opt['order'], name: opt['name'], venue: opt['venueid'], map: opt['mapid'], minimap: opt['minimapid'],
                          active: opt['active'],topbarColor: opt['topbarColor'],textColor: opt['textColor'],
                          idColor: opt['idColor']};

      this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});

      this.index = index;
      this.order = opt['order'];
      this.name = opt['name'];
      this.venue = opt['venueid'];
      this.map = opt['mapid'];
      this.minimap = opt['minimapid'];
      this.active = opt['active'];
      this.topbarColor = opt['topbarColor'];
      this.textColor = opt['textColor'];
      this.idColor = opt['idColor'];
      this.campo = opt['campo'];
      this.valor = opt['valor'];

      this.imgActive = opt['imgActive'];
      this.imgLogoactive = opt['imgLogoactive'];
      this.imgBgactive = opt['imgBgactive']

      this.modalRef.content = initialState;
  }

  deleteTab(tab, o) {
      this.optTabs.splice(o, 1);
      delete this.config.configurationFileDVM.tabOptionsJson[tab.name.toLowerCase()];
  }

  addToObject(obj, key, value, index) {
      // Create a temp object and index variable
      var temp = {};
      var i = 0;
  
      // Loop through the original object
      for (var prop in obj) {
          if (obj.hasOwnProperty(prop)) {
  
              // If the indexes match, add the new item
              if (i === index && key && value) {
                  temp[key] = value;
              }
  
              // Add the current item in the loop to the temp obj
              temp[prop] = obj[prop];
  
              // Increase the count
              i++;
  
          }
      }
  
      // If no index, add to the end
      if (!index && key && value) {
          temp[key] = value;
      }
  
      return temp;
  };

  get tabsOptions(){
      return this.optTabs;
  }

  uploadTabActiveImg(event, file){
      //for preview
      if (file.length === 0)
      return;

      var reader = new FileReader();
      reader.readAsDataURL(file[0]); 

      $("#activeImgName").removeClass("hidden");
      $("#activeImgName").text(file[0].name);

      reader.onload = (_event) => { 
          this.imgActive = reader.result;
      }

  }

  removeActiveImg() {
    this.imgActive = false;
    $("#activeImgName").text("");
  }

  uploadTabLogoactiveImg(event, file){
      //for preview
      if (file.length === 0)
      return;

      var reader = new FileReader();
      reader.readAsDataURL(file[0]); 
      reader.onload = (_event) => { 
          this.imgLogoactive = reader.result;
      }

      $("#logoactiveImgName").removeClass("hidden");
      $("#logoactiveImgName").text(file[0].name);
  }

  removeLogoactiveImg() {
    this.imgLogoactive = false;
    $("#logoactiveImgName").text("");
  }

  uploadTabBgactiveImg(event, file){
    //for preview
    if (file.length === 0)
    return;

    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
        this.imgBgactive = reader.result;
    }

    $("#bgactiveImgName").removeClass("hidden");
    $("#bgactiveImgName").text(file[0].name);
  }

  removeBgactiveImg() {
    this.imgBgactive = false;
    $("#bgactiveImgName").text("");
  }

  checkedTabsManual() {
    if($("#tabsCheckManual").prop('checked')) {
        this.tabsCheckManual = true;
    } else {
        this.tabsCheckManual = false;
    }
  }

  download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  onDownloadFilteringJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.filteringJson), "filtering.json", "application/json");
  }

  onDeleteFilteringJson() {
    this.config.configurationFileDVM.filteringJson = {};
  }

  onDownloadNavigationJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.navigationJson), "navigation.json", "application/json");
  }

  onDeleteNavigationJson(){
    this.config.configurationFileDVM.navigationJson = null;
  }

  onDownloadTabsJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.tabOptionsJson), "tabs.json", "application/json");
  }

  onDeleteTabsJson(){
    this.config.configurationFileDVM.tabOptionsJson = {};
  }

  onDownloadNeighboursJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.neighboursOptionsJson), "neighbours.json", "application/json");
  }

  onDeleteNeighboursJson(){
    this.config.configurationFileDVM.neighboursOptionsJson = {};
  }

  onDownloadInfomenuJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.infoMenuOptions), "infomenu.json", "application/json");
  }

  onDeleteInfomenuJson(){
    this.config.configurationFileDVM.infoMenuOptions = {};
  }

  onDownloadUnavailableJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.unavailableOptionsJson), "unavailable.json", "application/json");
  }

  onDeleteUnavailableJson(){
    this.config.configurationFileDVM.unavailableOptionsJson = {};
  }

  onDownloadTranslationJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.translationOptionsJson), "translation.json", "application/json");
  }

  onDeleteTranslationJson(){
    this.config.configurationFileDVM.translationOptionsJson = {};
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  // infomenu array & values
  infoMenuVenue;
  options = this.config.configurationFileDVM.infoMenuOptions;
  campo: string;
  valor: string;
  index: number;

  // create and delete menu
  // createOption() {
  //   if(this.campo != undefined && this.valor != undefined) {
  //       let l = this.options.length;
    
  //       var o = {
  //           index: l,
  //           campo: this.campo,
  //           valor: this.valor
  //       };
    
  //       this.options.push(o);
  //       this.index = null;
  //       this.campo = "";
  //       this.valor = "";
  //       this.modalRef.hide();
  //   }
  // }

  // createOptionEd() {
  //   //this.options[$("#index").val()].index = $("#index").val();
  //   this.options[$("#index").val()].campo = $("#campo").val();
  //   this.options[$("#index").val()].valor = $("#valor").val();
  //   this.index = null;
  //   this.campo = "";    
  //   this.valor = "";    
  //   this.modalRef.hide();
  // }

  // editOption(template: TemplateRef<any>, opt, index) {
  //   var initialState = {index: index, campo: opt['campo'], valor: opt['valor']};
  //   this.modalRef = this.modalService.show(template, {class: 'modal-md', initialState});
  //   this.campo = opt['campo'];
  //   this.valor = opt['valor'];
  //   this.index = index;
  //   this.modalRef.content = initialState;
  // }

  // deleteOption(o) {
  //   this.options.splice(o, 1);
  //   for(var i = o; i < this.options.length; i++) {
  //     this.options[i].index = this.options[i].index - 1;
  //   }
  // }

  // closeModal(){
  //   this.index = null;
  //   this.campo = "";    
  //   this.valor = "";  
  //   this.modalRef.hide();
  // }

  // get menuoptions() {
  //   return this.options;
  // }

  // auxTabJson;

  // get tables() {
  //   var op = [];
  //   if(this.auxTabJson) {
  //     for(var o in this.auxTabJson){
  //         op.push(this.auxTabJson[o]);
  //     }
  //   } else if (this.localJsonTabs) {
  //     for(var o in this.localJsonTabs){
  //       op.push(this.localJsonTabs[o]);
  //     }
  //   }
  //   return op;
  // }

  // moveTableElement(element, index){
  //   var row = $(element.path[2]).closest('tr');

  //   if ($(element.path[0]).hasClass('up')) {
  //     if(index !== 0) {
  //       row.prev().before(row);
  //       this.options[index].index = index - 1;
  //       this.options[index - 1].index = index;
  //     }
  //   } else {
  //     if(index+1 !== this.options.length) {
  //       row.next().after(row);
  //       this.options[index].index = index + 1;
  //       this.options[index + 1].index = index;
  //     }
  //   }

  //   this.options.sort(this.compareValues('index'));
  // }

  // compareValues(key, order = 'asc') {
  //   return function innerSort(a, b) {
  //     if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
  //       return 0;
  //     }
  
  //     const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
  //     const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
  
  //     let comparison = 0;
  //     if (varA > varB) {
  //       comparison = 1;
  //     } else if (varA < varB) {
  //       comparison = -1;
  //     }
  //     return (
  //       (order === 'desc') ? (comparison * -1) : comparison
  //     );
  //   };
  // }

  checkedBg() {
    if($("#bgCheck").prop('checked')) {
        this.bgCheck = true;
    } else {
        this.bgCheck = false;
    }
  }

  checkedFavicon() {
    if($("#faviconCheck").prop('checked')) {
        this.faviconCheck = true;
    } else {
        this.faviconCheck = false;
    }
  }

  checkedLogo() {
    if($("#logoCheck").prop('checked')) {
        this.logoCheck = true;
    } else {
        this.logoCheck = false;
    }
  }

  checkedFilters() {
    if($("#filtersCheck").prop('checked')) {
      this.filtersCheck = true;
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    } else {
      this.filtersCheck = false;
      document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
    }
  }

  checkedNavigation() {
    if($("#navigationCheck").prop('checked')) {
      this.navigationCheck = true;
      document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    } else {
      this.navigationCheck = false;
      document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
    }
  }

  checkedTabs() {
    if($("#tabsCheck").prop('checked')) {
        this.tabsCheck = true;
        document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
    } else {
        this.tabsCheck = false;
        document.getElementsByClassName("uploadBtn")[2].classList.add("disabled");
    }
  } 

  checkedNeighbours(){
    if($("#neighboursCheck").prop('checked')) {
        this.neighboursCheck = true;
        document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
    } else {
        this.neighboursCheck = false;
        document.getElementsByClassName("uploadBtn")[3].classList.add("disabled");
    }
  }

  checkedLeftRight() {
    if($("#leftRightCheck").prop('checked')) {
      this.leftRightCheck = true;
    } else {
      this.leftRightCheck = false;
    }
  }

  checkedInfoMenu() {
    if($("#infoMenuCheck").prop('checked')) {
        this.infoMenuCheck = true;
        document.getElementsByClassName("uploadBtn")[4].classList.remove("disabled");
    } else {
        this.infoMenuCheck = false;
        document.getElementsByClassName("uploadBtn")[4].classList.add("disabled");
    }
  }
  
  checkedSearchbar() {
    if($("#searchbarCheck").prop('checked')) {
        this.searchbarCheck = true;
        document.getElementById("searchbarCheck").classList.remove("disabled");
    } else {
        this.searchbarCheck = false;
        document.getElementById("searchbarCheck").classList.add("disabled");
    }
  } 

  checkedNavigationPlugion(){
    if($("#navigationPluginCheck").prop('checked')) {
        this.navigationPluginCheck = true;
        document.getElementById("navigationPluginCheck").classList.remove("disabled");
    } else {
        this.navigationPluginCheck = false;
        document.getElementById("navigationPluginCheck").classList.add("disabled");
    }
  }

  checkedIndoorPlugin(){
    if($("#indoorPluginCheck").prop('checked')) {
        this.indoorPluginCheck = true;
        this.multiIndoorCheck = false;
        document.getElementById("indoorPluginCheck").classList.remove("disabled");
    } else {
        this.indoorPluginCheck = false;
        document.getElementById("indoorPluginCheck").classList.add("disabled");
    }
  }

  checkedMultiIndoor(){
    if($("#multiIndoorCheck").prop('checked')) {
        this.multiIndoorCheck = true;
        this.indoorPluginCheck = false;
        document.getElementById("multiIndoorCheck").classList.remove("disabled");
    } else {
        this.multiIndoorCheck = false;
        document.getElementById("multiIndoorCheck").classList.add("disabled");
    }
  }

  checkedGalleryPlugin(){
    if($("#galleryPluginCheck").prop('checked')) {
        this.galleryPluginCheck = true;
        document.getElementById("galleryPluginCheck").classList.remove("disabled");
    } else {
        this.galleryPluginCheck = false;
        document.getElementById("galleryPluginCheck").classList.add("disabled");
    }
  }

  checkedBestNodes(){
    if($("#bestNodesCheck").prop('checked')) {
      this.bestNodesCheck = true;
      document.getElementById("bestNodesCheck").classList.remove("disabled");
    } else {
      this.bestNodesCheck = false;
      document.getElementById("bestNodesCheck").classList.add("disabled");
    }
  }

  checkedCopyLink() {
    if($("#copyLinkCheck").prop('checked')) {
      this.copyLinkCheck = true;
      document.getElementById("copyLinkCheck").classList.remove("disabled");
    } else {
      this.copyLinkCheck = false;
      document.getElementById("copyLinkCheck").classList.add("disabled");
    }
  }

  checkedUnavailable() {
    if($("#unavailableCheck").prop('checked')) {
        this.unavailableCheck = true;
        document.getElementsByClassName("uploadBtn")[5].classList.remove("disabled");
    } else {
        this.unavailableCheck = false;
        document.getElementsByClassName("uploadBtn")[5].classList.add("disabled");
    }
  } 

  checkedTranslation() {
    if($("#translationCheck").prop('checked')) {
        this.translationCheck = true;
        document.getElementsByClassName("uploadBtn")[6].classList.remove("disabled");
    } else {
        this.translationCheck = false;
        document.getElementsByClassName("uploadBtn")[6].classList.add("disabled");
    }
  } 

  @ViewChild('logo') inputLogo: ElementRef;
  @ViewChild('bg') inputBg: ElementRef;
  @ViewChild('fav') inputFav: ElementRef;


  uploadLogo(event, file) {
    //for upload
    this.logoUpload.clientMandateForm = event.srcElement.files[0];
    this.config.logo = this.logoUpload.clientMandateForm;

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.logoPreview = reader.result; 
      $("#logo-container").attr("src", this.config.logoPreview);
    }

    this.logoUploaded = true;
    $("#logoName").removeClass("hidden");
    $("#logoName").text(file[0].name);
  }

  removeLogo() {
    this.inputLogo.nativeElement.value = null;
    this.logoUploaded = false;
    $("#logoName").addClass("hidden");
    $("#logoName").text("");
    $("#logo-container").attr("src", "");
  }

  uploadFav(event, file) {
    //for upload
    this.favUpload.clientMandateForm = event.srcElement.files[0];
    this.config.favicon = this.favUpload.clientMandateForm;

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.favPreview = reader.result; 
      $("#fav-container").attr("src", this.config.favPreview);
    }

    this.favUploaded = true;
    $("#favName").removeClass("hidden");
    $("#favName").text(file[0].name);
  }

  removeFav() {
    this.inputFav.nativeElement.value = null;
    this.favUploaded = false;
    $("#favName").addClass("hidden");
    $("#favName").text("");
    $("#fav-container").attr("src", "");
  }

  uploadBg(event, file) {
    //for upload
    this.bgUpload.clientMandateForm = event.srcElement.files[0];
    this.config.background = this.bgUpload.clientMandateForm; 

    //for preview
    if (file.length === 0)
      return;
  
    var reader = new FileReader();
    reader.readAsDataURL(file[0]); 
    reader.onload = (_event) => { 
      this.config.bgPreview = reader.result; 
      $("#bg-container").attr("src", this.config.bgPreview);
    }

    this.bgUploaded = true;
    $("#bgName").removeClass("hidden");
    $("#bgName").text(file[0].name);
  }

  removeBg() {
    this.inputBg.nativeElement.value = null;
    this.bgUploaded = false;
    $("#bgName").addClass("hidden");
    $("#bgName").text("");
    $("#bg-container").attr("src", "");
  }

  validateData(){
    if($("#filtersCheck").prop('checked')){
      this.filtersCheck = true;
    } else {
      this.filtersCheck = false;
    }

    if($("#navigationCheck").prop('checked')){
      this.navigationCheck = true;
    } else {
      this.navigationCheck = false;
    }

    if($("#tabsCheck").prop('checked')){
      this.tabsCheck = true;
    } else {
      this.tabsCheck = false;
    }

    if($("#searchbarCheck").prop('checked')){
        this.searchbarCheck = true;
    } else {
        this.searchbarCheck = false;
    }

    if($("#infoMenuCheck").prop('checked')){
      this.infoMenuCheck = true;
    } else {
      this.infoMenuCheck = false;
    }

    if($("#navigationPluginCheck").prop('checked')) {
        this.navigationPluginCheck = true;
    } else {
        this.navigationPluginCheck = false;
    }

    if($("#indoorPluginCheck").prop('checked')) {
        this.indoorPluginCheck = true;
    } else {
        this.indoorPluginCheck = false;
    }

    if($("#multiIndoorCheck").prop('checked')) {
      this.multiIndoorCheck = true;
    } else {
        this.multiIndoorCheck = false;
    }

    if($("#galleryPluginCheck").prop('checked')) {
        this.galleryPluginCheck = true;
    } else {
        this.galleryPluginCheck = false;
    }

    if($("#neighboursCheck").prop('checked')){
      this.neighboursCheck = true;
    } else {
      this.neighboursCheck = false;
    }

    if($("#leftRightCheck").prop('checked')){
      this.leftRightCheck = true;
    } else {
      this.leftRightCheck = false;
    }

    if($("#bestNodesCheck").prop('checked')){
      this.bestNodesCheck = true;
    } else {
        this.bestNodesCheck = false;
    }

    if($("#pngLogoType").prop('checked')){
      this.logoType = "png";
    } else if($("#svgLogoType").prop('checked')) {
      this.logoType = "svg";
    }

    if($("#pngFaviconType").prop('checked')){
      this.faviconType = "png";
    } else if($("#svgFaviconType").prop('checked')) {
        this.faviconType = "svg";
    } else if($("#icoFaviconType").prop('checked')) {
        this.faviconType = "ico";
    } 

    if($("#unavailableCheck").prop('checked')){
      this.unavailableCheck = true;
    } else {
      this.unavailableCheck = false;
    }

    if($("#tabsCheckManual").prop('checked')){
      this.tabsCheckManual = true;
    } else {
        this.tabsCheckManual = false;
    }

    if($("#translationCheck").prop('checked')){
      this.translationCheck = true;
    } else {
      this.translationCheck = false;
    }

    if($("#backgroundColor").val() !== "") {
      this.config.configurationFileDVM.backgroundColor = $("#backgroundColor").val();
    }

    if($("#copyLinkCheck").prop('checked')) {
      this.copyLinkCheck = true;
    } else {
      this.copyLinkCheck = false;
    }

    this.config.configurationFileDVM.filters = this.filtersCheck;
    this.config.configurationFileDVM.navigation = this.navigationCheck;
    this.config.configurationFileDVM.tabs = this.tabsCheck;
    this.config.configurationFileDVM.tabsManual = this.tabsCheckManual;
    this.config.configurationFileDVM.searchbar = this.searchbarCheck;
    this.config.configurationFileDVM.infoMenuCheck = this.infoMenuCheck;
    this.config.configurationFileDVM.infoMenuTitle = this.infoMenuTitle;
    this.config.configurationFileDVM.logo = this.logoCheck;
    this.config.configurationFileDVM.bg = this.bgCheck;
    this.config.configurationFileDVM.favicon = this.faviconCheck;
    this.config.configurationFileDVM.navigationPluginCheck = this.navigationPluginCheck;
    this.config.configurationFileDVM.indoorPluginCheck = this.indoorPluginCheck;
    this.config.configurationFileDVM.multiIndoorCheck = this.multiIndoorCheck;
    this.config.configurationFileDVM.galleryPluginCheck = this.galleryPluginCheck;
    this.config.configurationFileDVM.neighbours = this.neighboursCheck;
    this.config.configurationFileDVM.leftRight = this.leftRightCheck;
    this.config.configurationFileDVM.bestNodesCheck = this.bestNodesCheck;
    this.config.configurationFileDVM.logoUrl = this.logoUrl;
    this.config.configurationFileDVM.logoType = this.logoType;
    this.config.configurationFileDVM.faviconType = this.faviconType;
    this.config.configurationFileDVM.unavailable = this.unavailableCheck;
    this.config.configurationFileDVM.translation = this.translationCheck;
    this.config.configurationFileDVM.backgroundColor = this.backgroundColor;
    this.config.configurationFileDVM.copyLinkCheck = this.copyLinkCheck;


    // if(this.tabsCheck){
    //   this.config.configurationFileDVM.infoMenuOptions = this.auxTabJson;
    // } else {
    //   this.config.configurationFileDVM.infoMenuOptions = this.options;
    // }

    if(this.config.configurationFileDVM.filteringJson && this.config.configurationFileDVM.navigationJson) {
      this.dvm.changeJson(this.config.configurationFileDVM.filteringJson, this.config.configurationFileDVM.navigationJson);
    } else if(this.config.configurationFileDVM.filteringJson) {
      this.config.configurationFileDVM.navigationJson = {outdoor:[]};
      this.dvm.changeJson(this.config.configurationFileDVM.filteringJson, {outdoor:[]});
    } else if(this.config.configurationFileDVM.navigationJson) {
      this.config.configurationFileDVM.filteringJson = {filtering:[]};
      this.dvm.changeJson({filtering:[]}, this.config.configurationFileDVM.navigationJson);
    } else {
      this.config.configurationFileDVM.filteringJson = {filtering:[]};
      this.config.configurationFileDVM.navigationJson = {outdoor:[]};
      this.dvm.changeJson({filtering:[]}, {outdoor:[]});
    }

    this.config.breadcrumbs.push({name:"Preview", state:"common.previewdvm"});
    this.state.go('common.previewdvm');
  }

  filtJson: any = { clientDoc: {} };
  navJson: any = { clientDoc: {} };
  tabJson: any = { clientDoc: {} };
  neighboursOptJson: any = { clientDoc: {} };
  infomenuJson: any = { clientDoc: {} };
  unavailableOptJson: any = { clientDoc: {} };
  translationOptJson: any = { clientDoc: {} };

  filteringJson = false;
  navigationJson = false;
  isInfomenuJson = false;
  tabsJson = false;
  isNeighboursJson = false;
  isUnavailableJson = false;
  isTranslationJson = false;

  uploadFile(event, file, key) {
    if(key === "filtering") {
      this.filtJson.clientMandateForm = event.srcElement.files[0];
      this.config.filteringJson = this.filtJson.clientMandateForm; 
    } else if(key === "navigation"){
      this.navJson.clientMandateForm = event.srcElement.files[0];
      this.config.navigationJson = this.navJson.clientMandateForm; 
    } else if(key === "tabs"){
      this.tabJson.clientMandateForm = event.srcElement.files[0];
      this.config.tabOptionsJson = this.tabJson.clientMandateForm; 
    } else if(key === "neighbours") {
      this.neighboursOptJson.clientMandateForm = event.srcElement.files[0];
      this.config.neighboursJson = this.neighboursOptJson.clientMandateForm;
    } else if(key === "infomenu") {
      this.infomenuJson.clientMandateForm = event.srcElement.files[0];
      this.config.infomenuJson = this.infomenuJson.clientMandateForm;
    }else if (key === "unavailable") {
      this.unavailableOptJson.clientMandateForm = event.srcElement.files[0];
      this.config.unavailableOptionsJson = this.unavailableOptJson.clientMandateForm;
    }else if (key === "translation") {
      this.translationOptJson.clientMandateForm = event.srcElement.files[0];
      this.config.translationOptionsJson = this.translationOptJson.clientMandateForm;
    }

    if (file.length === 0)
      return;

    var reader = new FileReader();

    reader.readAsText(file[0]); 
    reader.onload = () => { 
      if(key === "filtering") {
        this.config.filteringJsonPre = reader.result; 
        this.config.filteringJsonPre = JSON.parse(this.config.filteringJsonPre);
        this.filteringJson = true;
        $("#filteringJson").removeClass("hidden");
        $("#filteringJson").text(file[0].name);
        this.config.configurationFileDVM.filteringJson = this.config.filteringJsonPre;

      } else if(key === "navigation"){
        this.config.navigationJsonPre = reader.result;
        this.config.navigationJsonPre = JSON.parse(this.config.navigationJsonPre);
        this.navigationJson = true;
        $("#navigationJson").removeClass("hidden");
        $("#navigationJson").text(file[0].name);
        this.config.configurationFileDVM.navigationJson = this.config.navigationJsonPre;
        
      } else if(key === "tabs") {
        this.config.tabOptionsJsonPre = reader.result;
        this.config.tabOptionsJsonPre = JSON.parse(this.config.tabOptionsJsonPre);
        this.tabsJson = true;
        $("#tabsJson").removeClass("hidden");
        $("#tabsJson").text(file[0].name);
        this.config.configurationFileDVM.tabOptionsJson = this.config.tabOptionsJsonPre;
        //this.auxTabJson = this.config.tabOptionsJsonPre;

      } else if(key === "neighbours") {
        this.config.neighboursOptionsJsonPre = reader.result;
        this.config.neighboursOptionsJsonPre = JSON.parse(this.config.neighboursOptionsJsonPre);
        this.isNeighboursJson = true;
        $("#neighboursJson").removeClass("hidden");
        $("#neighboursJson").text(file[0].name);
        this.config.configurationFileDVM.neighboursOptionsJson = this.config.neighboursOptionsJsonPre;

      } else if(key === "infomenu") {
        this.config.infomenuJsonPre = reader.result;
        this.config.infomenuJsonPre = JSON.parse(this.config.infomenuJsonPre);
        this.isInfomenuJson = true;
        $("#infomenuJson").removeClass("hidden");
        $("#infomenuJson").text(file[0].name);
        this.config.configurationFileDVM.infoMenuOptions = this.config.infomenuJsonPre;
      }else if(key === "unavailable") {
        this.config.unavailableOptionsJsonPre = reader.result;
        this.config.unavailableOptionsJsonPre = JSON.parse(this.config.unavailableOptionsJsonPre);
        this.isUnavailableJson = true;
        $("#unavailableJson").removeClass("hidden");
        $("#unavailableJson").text(file[0].name);
        this.config.configurationFileDVM.unavailableOptionsJson = this.config.unavailableOptionsJsonPre;
      }else if(key === "translation") {
        this.config.translationOptionsJsonPre = reader.result;
        this.config.translationOptionsJsonPre = JSON.parse(this.config.translationOptionsJsonPre);
        this.isTranslationJson = true;
        $("#translationJson").removeClass("hidden");
        $("#translationJson").text(file[0].name);
        this.config.configurationFileDVM.translationOptionsJson = this.config.translationOptionsJsonPre;
      }
    }
  }

  // remove files uploaded
  @ViewChild('filterJson') inputFilt: ElementRef;
  @ViewChild('navigation') inputNav: ElementRef;
  @ViewChild('neighbours') inputNeighbours: ElementRef;
  @ViewChild('infomenu') inputInfomenu: ElementRef;
  @ViewChild('blocks') inputUnavailable: ElementRef;
  @ViewChild('translation') inputTranslation: ElementRef;
  
  removeFile(key) {
    if(key === "filtering") {
        this.inputFilt.nativeElement.value = null;
        this.filteringJson = false;
        $("#filteringJson").addClass("hidden");
        $("#filteringJson").text("");
        this.config.configurationFileDVM.filteringJson = null;

    } else if(key === "navigation"){
        this.inputNav.nativeElement.value = null;
        this.navigationJson = false;
        $("#navigationJson").addClass("hidden");
        $("#navigationJson").text("");
        this.config.configurationFileDVM.navigationJson = null;

    } else if(key === "neighbours") {
        this.inputNeighbours.nativeElement.value = null;
        this.isNeighboursJson = false;
        $("#neighboursJson").addClass("hidden");
        $("#neighboursJson").text("");
        this.config.configurationFileDVM.neighboursOptionsJson = null;
    } else if (key === "infomenu") {
        this.inputInfomenu.nativeElement.value = null;
        this.isInfomenuJson = false;
        $("#infomenuJson").addClass("hidden");
        $("#infomenuJson").text("");
        this.config.configurationFileDVM.infoMenuOptions = null;
    }else if(key === "unavailable") {
        this.inputUnavailable.nativeElement.value = null;
        this.isUnavailableJson = false;
        $("#unavailableJson").addClass("hidden");
        $("#unavailableJson").text("");
    }else if(key === "translation") {
        this.inputTranslation.nativeElement.value = null;
        this.isTranslationJson = false;
        $("#translationJson").addClass("hidden");
        $("#translationJson").text("");
    }
  }

  tabsJsonToDownload;
  logoUrlName;
  bgUrlName;
  faviconUrlName;

  constructor(private modalService: BsModalService, private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) { }

  ngOnInit() {
    this.filtersCheck = this.config.configurationFileDVM.filters;
    this.navigationCheck = this.config.configurationFileDVM.navigation;
    this.searchbarCheck = this.config.configurationFileDVM.searchbar;
    this.infoMenuCheck = this.config.configurationFileDVM.infoMenuCheck;
    this.tabsCheck = this.config.configurationFileDVM.tabs;
    this.neighboursCheck = this.config.configurationFileDVM.neighbours;
    this.infoMenuCheck = this.config.configurationFileDVM.infoMenuCheck;
    this.unavailableCheck = this.config.configurationFileDVM.unavailable;
    this.tabsCheckManual = this.config.configurationFileDVM.tabsManual;
    this.translationCheck = this.config.configurationFileDVM.translation;
    this.leftRightCheck = this.config.configurationFileDVM.leftRight;
    this.copyLinkCheck = this.config.configurationFileDVM.copyLinkCheck;

    for(var i in this.config.configurationFileDVM.tabOptionsJson){
      this.optTabs.push(this.config.configurationFileDVM.tabOptionsJson[i])
    }

    if(this.filtersCheck) {
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    }

    if(this.navigationCheck) {
      document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    }

    if(this.tabsCheck) {
      document.getElementsByClassName("uploadBtn")[2].classList.remove("disabled");
    }

    if(this.neighboursCheck) {
      document.getElementsByClassName("uploadBtn")[3].classList.remove("disabled");
    }

    if(this.infoMenuCheck) {
      document.getElementsByClassName("uploadBtn")[4].classList.remove("disabled");
    }

    if(this.unavailableCheck) {
      document.getElementsByClassName("uploadBtn")[5].classList.remove("disabled");
    }

    if(this.translationCheck) {
      document.getElementsByClassName("uploadBtn")[6].classList.remove("disabled");
    }

    var name = this.config.configurationFileDVM.minisiteUrl;

    if(name) {
      this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.png`;
      this.bgUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/bg.jpg`;
      this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.png`;
    }

    if(this.config.configurationFileDVM.filters){
      this.filtersCheck = true
    } else {
      this.filtersCheck = false
    }

    if(this.logoType === 'png') {
      $("#pngLogoType").prop('checked', true);
      $("#svgLogoType").prop('checked', false);
      if(name) {
        this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.png`;
      }
    } else if(this.logoType === 'svg') {
      $("#pngLogoType").prop('checked', false);
      $("#svgLogoType").prop('checked', true);
      if(name){
        this.logoUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/logo.svg`;
      }
    }

    if(this.faviconType === 'png') {
      $("#pngFaviconType").prop('checked', true);
      $("#svgFaviconType").prop('checked', false);
      $("#icoFaviconType").prop('checked', false);
      if(name){
        this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.png`;
      }
    } else if(this.faviconType === 'svg') {
      $("#pngFaviconType").prop('checked', false);
      $("#svgFaviconType").prop('checked', true);
      $("#icoFaviconType").prop('checked', false);
      if(name){
        this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.svg`;
      }
    } else if(this.faviconType === 'ico') {
      $("#pngFaviconType").prop('checked', false);
      $("#svgFaviconType").prop('checked', false);
      $("#icoFaviconType").prop('checked', true);
      if(name){
        this.faviconUrlName = `https://3ddigitalvenue.com/3dmap/pro-sites/${name}/favicon.ico`;
      }
    }    
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as global from '../../services/global-functions'
import { ConfigurationService } from 'src/app/services/configuration.service';
import { StateService } from '@uirouter/core';
import { PreviewDVMService } from '../preview-dvm/preview-dvm.service';
declare var $: any;

@Component({
  selector: 'app-content-dvm',
  templateUrl: './content-dvm.component.html',
  styleUrls: ['./content-dvm.component.scss']
})
export class ContentDVMComponent implements OnInit {
  //config
  minisiteUrl: string = this.config.configurationFileDVM.minisiteUrl;
  venueID: string = this.config.configurationFileDVM.venueID;
  mapID: string = this.config.configurationFileDVM.mapID;
  minimapID: string = this.config.configurationFileDVM.minimapID;
  ticketsUrl: string = this.config.configurationFileDVM.ticketsUrl;
  ticketsName: string = this.config.configurationFileDVM.ticketsName;
  venueName: string = this.config.configurationFileDVM.venueName;
  viewType: string = this.config.configurationFileDVM.viewType;
  exceptions: string[] = this.config.configurationFileDVM.exceptions;
  buyTickets:boolean = this.config.configurationFileDVM.buyTickets;
  popupCheck: string = this.config.configurationFileDVM.popupImg;
  integrationType:string = this.config.configurationFileDVM.integrationType;
  libraryVersion:string = this.config.configurationFileDVM.libraryVersion;
  viewerIntegration:string = this.config.configurationFileDVM.viewerIntegration;
  hasPano: boolean = this.config.configurationFileDVM.hasPano;
  hasMinimap: boolean = this.config.configurationFileDVM.hasMinimap;
  subtitleName: string = this.config.configurationFileDVM.subtitleName ? this.config.configurationFileDVM.subtitleName : "3D seatmap";
  popupJsonCheck: boolean = this.config.configurationFileDVM.popupJsonCheck;
  popupjson: any = { clientDoc: {} };
  isPopupJson = false;
  localJsonPopup = this.config.configurationFileDVM.infoMenuOptions;
  exceptionsJsonCheck: boolean = this.config.configurationFileDVM.exceptionsJsonCheck;
  exceptionsjson: any = { clientDoc: {} };
  isExceptionsJson = false;
  localJsonExceptions = this.config.configurationFileDVM.exceptionsJson;
  onlyViewer = this.config.configurationFileDVM.onlyViewer;
  viewerVersion = this.config.configurationFileDVM.viewerVersion;

  checked() {
    if($("#buyTickets").prop('checked')){
      this.buyTickets = true
    } else {
      this.buyTickets = false
    }
  }

  checkedMinimap() {
    if($("#hasMinimap").prop('checked')){
      this.hasMinimap = true;
    } else {
      this.hasMinimap = false;
    }
  }

  checkedOnlyViewer(){
    if($("#onlyViewer").prop('checked')){
      this.onlyViewer = true;
    } else {
      this.onlyViewer = false;
    }
  }

  validateForm(form: FormData) {

  }

  uploadFile(event, file, key) {
    if(key === "popupjson") {
      this.popupjson.clientMandateForm = event.srcElement.files[0];
      this.config.popupJson = this.popupjson.clientMandateForm; 
    } else if (key === "exceptionsjson") {
      this.exceptionsjson.clientMandateForm = event.srcElement.files[0];
      this.config.exceptionsJson = this.exceptionsjson.clientMandateForm; 
    }

    if (file.length === 0)
      return;

    var reader = new FileReader();

    reader.readAsText(file[0]); 
    reader.onload = () => {
      if(key === "popupjson") {
        this.config.popupJsonPre = reader.result; 
        this.config.popupJsonPre = JSON.parse(this.config.popupJsonPre);
        this.isPopupJson = true;
        $("#popupJsonName").removeClass("hidden");
        $("#popupJsonName").text(file[0].name);
        this.config.configurationFileDVM.popupJson = this.config.popupJsonPre;
      } else if(key === "exceptionsjson") {
        this.config.exceptionsJsonPre = reader.result; 
        this.config.exceptionsJsonPre = JSON.parse(this.config.exceptionsJsonPre);
        this.isExceptionsJson = true;
        $("#exceptionsJsonName").removeClass("hidden");
        $("#exceptionsJsonName").text(file[0].name);
        this.config.configurationFileDVM.exceptionsJson = this.config.exceptionsJsonPre;
      }
    }
  }

  @ViewChild('popupjson') inputPopup: ElementRef;
  @ViewChild('exceptionsjson') inputExceptions: ElementRef;

  removeFile(key) {
    if(key === "popupjson") {
      this.inputPopup.nativeElement.value = null;
      this.isPopupJson = false;
      $("#popupJsonName").addClass("hidden");
      $("#popupJsonName").text("");
      this.config.configurationFileDVM.popupJson = null;
    } else if(key === "exceptionsjson") {
      this.inputExceptions.nativeElement.value = null;
      this.isExceptionsJson = false;
      $("#exceptionsJsonName").addClass("hidden");
      $("#exceptionsJsonName").text("");
      this.config.configurationFileDVM.exceptionsJson = null;
    }
  }

  checkedPopupJson() {
    if($("#popupJsonCheck").prop('checked')) {
        this.popupJsonCheck = true;
        document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
    } else {
        this.popupJsonCheck = false;
        document.getElementsByClassName("uploadBtn")[1].classList.add("disabled");
    }
  }


  checkedExceptionsJson() {
    if($("#exceptionsJsonCheck").prop('checked')) {
      this.exceptionsJsonCheck = true;
      document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
    } else {
        this.exceptionsJsonCheck = false;
        document.getElementsByClassName("uploadBtn")[0].classList.add("disabled");
    }
  }

  onDownloadPopupJson(){
    this.download(JSON.stringify(this.config.configurationFileDVM.popupJson), "popup.json", "application/json");
  }

  onDownloadExceptionsJson() {
    this.download(JSON.stringify(this.config.configurationFileDVM.exceptionsJson), "exceptions.json", "application/json");
  }

  download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  validateData() {
    if(this.exceptions) {
        var toArray = this.exceptions.toString().replace(/\s/g, ",").split(",");
        var removeSpaces = toArray.filter(Boolean)
        this.exceptions = removeSpaces;
    } else {
        this.exceptions = [];
    }
    
    if($("#section").prop('checked')) {
        this.viewType = "section"
    } else {
        this.viewType = "seat"
    }

    this.config.configurationFileDVM.minisiteUrl = this.minisiteUrl;
    this.config.configurationFileDVM.venueID = this.venueID;
    this.config.configurationFileDVM.mapID = this.mapID;
    this.config.configurationFileDVM.minimapID = this.minimapID;

    if(this.buyTickets) {
      if(this.ticketsUrl.includes("https://")) {
        this.ticketsUrl = this.ticketsUrl;
      } else {
        this.ticketsUrl = "https://"+this.ticketsUrl;
      }

      this.config.configurationFileDVM.ticketsUrl = this.ticketsUrl;
      this.config.configurationFileDVM.ticketsName = this.ticketsName;
      this.config.configurationFileDVM.buyTickets = true;
    } else {
      this.config.configurationFileDVM.buyTickets = false;
    }

    if($("#img").prop('checked')){
      this.popupCheck = "img";
    } else {
      this.popupCheck = "noimg";
    }

    if($("#popupJsonCheck").prop('checked')){
      this.popupJsonCheck = true;
    } else {
      this.popupJsonCheck = false;
    }

    if($("#dvm").prop('checked')){
      this.integrationType = "dvm";
    } else {
      this.integrationType = "bumper";
    }

    if($("#stableLib").prop('checked')){
      this.libraryVersion = "stable";
    } else if($("#latestLib").prop('checked')) {
      this.libraryVersion = "latest";
    } else if($("#devLib").prop('checked')){
      this.libraryVersion = "development";
    }

    if($("#newV").prop('checked')){
      this.viewerIntegration = "new";
    } else {
      this.viewerIntegration = "old";
    }

    if($("#pano").prop('checked')){
      this.hasPano = true;
    } else {
      this.hasPano = false;
    }

    if($("#exceptionsJsonCheck").prop('checked')){
      this.exceptionsJsonCheck = true;
    } else {
      this.exceptionsJsonCheck = false;
    }

    if($("#onlyViewer").prop('checked')){
      this.onlyViewer = true;
    } else {
      this.onlyViewer = false;
    }

    this.config.configurationFileDVM.popupImg = this.popupCheck;
    this.config.configurationFileDVM.venueName = this.venueName;
    this.config.configurationFileDVM.subtitleName = this.subtitleName;
    this.config.configurationFileDVM.viewType = this.viewType;
    this.config.configurationFileDVM.exceptions = this.exceptions;
    this.config.configurationFileDVM.flag = "DVM";
    this.config.configurationFileDVM.integrationType = this.integrationType;
    this.config.configurationFileDVM.viewerIntegration = this.viewerIntegration;
    this.config.configurationFileDVM.libraryVersion = this.libraryVersion;
    this.config.configurationFileDVM.hasPano = this.hasPano;
    this.config.configurationFileDVM.hasMinimap = this.hasMinimap;
    this.config.configurationFileDVM.popupJsonCheck = this.popupJsonCheck;
    this.config.configurationFileDVM.exceptionsJsonCheck = this.exceptionsJsonCheck;
    this.config.configurationFileDVM.onlyViewer = this.onlyViewer;
    this.config.configurationFileDVM.viewerVersion = this.viewerVersion;

    if(this.hasMinimap) {
      this.dvm.changeVenue(this.venueID, this.mapID, this.minimapID);
    } else {
      this.dvm.changeVenue(this.venueID, this.mapID, null);
    }

    this.config.breadcrumbs.push({name:"Custom", state:"common.customdvm"});
    this.state.go('common.customdvm');
    
  }

  constructor(private config: ConfigurationService, private state: StateService, private dvm: PreviewDVMService) {
  }

  ngOnInit() {
    if(!global.getCookie('access_token')) {
      this.state.go('common.login');
    } else {
      if(this.viewType === 'seat') {
        $("#seat").prop('checked', true);
        $("#section").prop('checked', false);
      } else if(this.viewType === 'section') {
        $("#seat").prop('checked', false);
        $("#section").prop('checked', true);
      }

      if(this.popupCheck === 'img') {
        $("#img").prop('checked', true);
        $("#noImg").prop('checked', false);
      } else if(this.popupCheck === 'noimg') {
        $("#img").prop('checked', false);
        $("#noImg").prop('checked', true);
      }

      if(this.integrationType === 'dvm') {
        $("#dvm").prop('checked', true);
        $("#bumper").prop('checked', false);
      } else if(this.integrationType === 'bumper') {
        $("#dvm").prop('checked', false);
        $("#bumper").prop('checked', true);
      }

      if(this.viewerIntegration === 'new') {
        $("#newV").prop('checked', true);
        $("#oldV").prop('checked', false);
      } else if(this.viewerIntegration === 'old') {
        $("#newV").prop('checked', false);
        $("#oldV").prop('checked', true);
      }

      if(this.hasPano === true) {
        $("#pano").prop('checked', true);
        $("#noPano").prop('checked', false);
      } else if(this.hasPano === false) {
        $("#pano").prop('checked', false);
        $("#noPano").prop('checked', true);
      }

      if(this.libraryVersion === 'stable') {
        $("#stableLib").prop('checked', true);
        $("#latestLib").prop('checked', false);
        $("#devLib").prop('checked', false);
      } else if(this.libraryVersion === 'latest') {
        $("#stableLib").prop('checked', false);
        $("#latestLib").prop('checked', true);
        $("#devLib").prop('checked', false);
      }else if(this.libraryVersion === 'development') {
        $("#stableLib").prop('checked', false);
        $("#latestLib").prop('checked', false);
        $("#devLib").prop('checked', true);
      }

      if(this.popupJsonCheck) {
        document.getElementsByClassName("uploadBtn")[1].classList.remove("disabled");
      }

      if(this.exceptionsJsonCheck) {
        document.getElementsByClassName("uploadBtn")[0].classList.remove("disabled");
      }

    }
  }
}
